<template>
  <div style="margin-top:15px;">
    <el-row>
      <el-col :xs="6" :sm="3" :md="3" :lg="3" :xl="1">
        <div id="funding" class="btn" @click="jump($event)">
          <img class="btnImg" src="/img/home_page_fund_situation_icon_btn.png" alt="">
          <span class="btnText">资金情况</span>
        </div>
      </el-col>
      <el-col :xs="6" :sm="3" :md="3" :lg="3" :xl="1">
        <div id="payroll" class="btn" @click="jump($event)">
          <img class="btnImg" src="/img/home_page_payroll_icon_btn.png" alt="">
          <span class="btnText">工资单</span>
        </div>
      </el-col>
      <el-col :xs="6" :sm="3" :md="3" :lg="3" :xl="1">
        <div id="resources" class="btn" @click="jump($event)">
          <img class="btnImg" src="/img/home_page_human_resources_icon_btn.png" alt="">
          <span class="btnText">人力资源</span>
        </div>
      </el-col>
      <el-col :xs="6" :sm="3" :md="3" :lg="3" :xl="1">
        <div id="stock" class="btn" @click="jump($event)">
          <img class="btnImg" src="/img/home_page_stock_icon_btn.png" alt="">
          <span class="btnText">存货</span>
        </div>
      </el-col>
      <el-col :xs="6" :sm="3" :md="3" :lg="3" :xl="1">
        <div id="profit" class="btn" @click="jump($event)">
          <img class="btnImg" src="/img/home_page_profit_situation_icon_btn.png" alt="">
          <span class="btnText">利润情况</span>
        </div>
      </el-col>
      <el-col :xs="6" :sm="3" :md="3" :lg="3" :xl="1">
        <div id="tax" class="btn" @click="jump($event)">
          <img class="btnImg" src="/img/home_page_tax_situation_icon_btn.png" alt="">
          <span class="btnText">纳税情况</span>
        </div>
      </el-col>
      <el-col :xs="6" :sm="3" :md="3" :lg="3" :xl="1">
        <div id="payable" class="btn" @click="jump($event)">
          <img class="btnImg" src="/img/home_page_payables_icon_btn.png" alt="">
          <span class="btnText">应付项目</span>
        </div>
      </el-col>
      <el-col :xs="6" :sm="3" :md="3" :lg="3" :xl="1">
        <div id="receivable" class="btn" @click="jump($event)">
          <img class="btnImg" src="/img/home_page_receivables_icon_btn.png" alt="">
          <span class="btnText">应收项目</span>
        </div>
      </el-col>
      <el-col :xs="6" :sm="3" :md="3" :lg="3" :xl="1">
        <div id="statement" class="btn" @click="jump($event)">
          <img class="btnImg" src="/img/home_page_income_statement_icon_btn.png" alt="">
          <span class="btnText">利润表</span>
        </div>
      </el-col>
      <el-col :xs="6" :sm="3" :md="3" :lg="3" :xl="1">
        <div id="sheet" class="btn" @click="jump($event)">
          <img class="btnImg" src="/img/home_page_balance_sheet_icon_btn.png" alt="">
          <span class="btnText" style="margin-left:-2px;">资产负债表</span>
        </div>
      </el-col>
      <el-col :xs="6" :sm="3" :md="3" :lg="3" :xl="1">
        <div id="management" class="btn" @click="jump($event)">
          <img class="btnImg" src="/img/home_page_asset_management_icon_btn.png" alt="">
          <span class="btnText">资产管理</span>
        </div>
      </el-col>
      <el-col :xs="6" :sm="3" :md="3" :lg="3" :xl="1">
        <div id="delivery" class="btn" @click="jump($event)">
          <img class="btnImg" src="/img/home_page_document_express_delivery_icon_btn.png" alt="">
          <span class="btnText">单据速递</span>
        </div>
      </el-col>
      <el-col :xs="6" :sm="3" :md="3" :lg="3" :xl="1">
        <div id="upload" class="btn" @click="jump($event)">
          <img class="btnImg" src="/img/home_page_invoice_upload_icon_btn.png" alt="">
          <span class="btnText">发票上传</span>
        </div>
      </el-col>
      <el-col :xs="6" :sm="3" :md="3" :lg="3" :xl="1">
        <div id="over" class="btn" @click="jump($event)">
          <img class="btnImg" src="/img/hopme_page_control_over_invoices_icon_btn.png" alt="">
          <span class="btnText">发票管理</span>
        </div>
      </el-col>
      <el-col :xs="6" :sm="3" :md="3" :lg="3" :xl="1">
        <div id="marketing" class="btn" @click="jump($event)">
          <img class="btnImg" src="/img/home_page_marketing_management_icon_btn.png" alt="">
          <span class="btnText">市场营销</span>
        </div>
      </el-col>
      <el-col :xs="6" :sm="3" :md="3" :lg="3" :xl="1">
        <div id="business" class="btn" @click="jump($event)">
          <img class="btnImg" src="/img/home_page_business_circles_icon_btn.png" alt="">
          <span class="btnText">工商注册</span>
        </div>
      </el-col>
      <el-col :xs="6" :sm="3" :md="3" :lg="3" :xl="1">
        <div id="legal" class="btn" @click="jump($event)">
          <img class="btnImg" src="/img/home_page_legal_adviser_icon_btn.png" alt="">
          <span class="btnText">法律顾问</span>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'whole',
  data () {
    return {
    }
  },
  created () {

  },
  methods: {
    jump (e) {
      switch (e.currentTarget.id) {
        case 'funding':
          this.$router.push({ name: 'funding' })
          break
        case 'payroll':
          // this.$router.push({ name: 'payroll' })
          this.$confirm('该功能暂未开放', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
          }).catch(() => {
          })
          break
        case 'resources':
          // this.$router.push({ path: '/resources' })
          this.$confirm('该功能暂未开放', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
          }).catch(() => {
          })
          break
        case 'stock':
          this.$router.push({ path: '/stock' })
          break
        case 'profit':
          this.$router.push({ path: '/profit' })
          break
        case 'tax':
          this.$router.push({ path: '/tax' })
          break
        case 'payable':
          this.$router.push({ path: '/payable' })
          break
        case 'receivable':
          this.$router.push({ path: '/receivable' })
          break
        case 'statement':
          this.$router.push({ path: '/statement' })
          break
        case 'sheet':
          this.$router.push({ path: '/sheet' })
          break
        case 'management':
          // this.$router.push({ path: '/management' })
          this.$confirm('该功能暂未开放', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
          }).catch(() => {
          })
          break
        case 'delivery':
          // this.$router.push({ path: '/delivery' })
          this.$confirm('该功能暂未开放', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
          }).catch(() => {
          })
          break
        case 'upload':
          // this.$router.push({ path: '/upload' })
          this.$confirm('该功能暂未开放', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
          }).catch(() => {
          })
          break
        case 'over':
          // this.$router.push({ path: '/over' })
          this.$confirm('该功能暂未开放', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
          }).catch(() => {
          })
          break
        case 'marketing':
          // this.$router.push({ path: '/marketing' })
          this.$confirm('该功能暂未开放', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
          }).catch(() => {
          })
          break
        case 'business':
          // this.$router.push({ path: '/business' })
          this.$confirm('该功能暂未开放', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
          }).catch(() => {
          })
          break
        case 'legal':
          // this.$router.push({ path: '/legal' })
          this.$confirm('该功能暂未开放', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
          }).catch(() => {
          })
          break
      }
    }
  }
}
</script>

<style>
.btn {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.btnImg {
  width: 53px;
  height: 53px;
  margin-bottom: 5px;
  margin-left: 5px;
}

.btnText {
  font-size: 12px;
  color: rgba(8, 27, 58, 1);
}
</style>
