<template>
  <div>
    <div class="backShort">
      <div style="display: flex;flex-direction: row;justify-content: space-between;">
        <div class="backShortTitle">
          <span class="textClass">{{cname}}</span>
        </div>
        <div class="backShortTitle2" @click="testLayerFooter()">
          <span class="textClass">{{selected_inv_time}}</span>
          <img src="/img/home_page_drop_down_box_btn.png" style="width:13px;height:8px;margin-top:4px;margin-left:5px;">
        </div>
      </div>
    </div>
    <div class="fu-open">
      <div class="fu-deposit" @click="deposit()">
        <span class="fu-d-text">{{subjectName}}</span>
        <img class="fu-d-img" src="/img/qietu/pay_taxes_drop_down_btn.png">
      </div>
      <span class="fu-show">{{bnlj}}</span>
      <span class="fu-prompt">(期末余额)</span>
      <div class="fu-sort">
        <div class="fu-directory">
          <span class="fu-s-title">收入</span>
          <span class="fu-s-content">{{shouru}}</span>
        </div>
        <div class="fu-s-line"></div>
        <div class="fu-directory">
          <span class="fu-s-title">支出</span>
          <span class="fu-s-content">{{zhichu}}</span>
        </div>
      </div>
    </div>
    <div class="fu-display">
      <div class="fu-d-heading" @click="change()">
        <span class="fu-d-title">{{subjectName}}</span>
        <img class="fu-d-img" src="/img/qietu/income_statement_btn.png" v-if="hasChild">
      </div>
      <div class="fu-d-css">
        <div class="fu-period">
          <span class="fu-p-text">{{qcye}}</span>
          <span class="fu-p-content">期初</span>
        </div>
        <div class="fu-end">
          <span class="fu-p-text">{{qmye}}</span>
          <span class="fu-p-content">期末</span>
        </div>
      </div>
    </div>
    <div v-for="item of items" :key="item.kmbh">
      <div class="fu-display-i" v-if="dakai">
        <div class="fu-d-heading">
          <span class="fu-d-title">{{item.kmmc}}</span>
        </div>
        <div class="fu-d-css">
          <div class="fu-period">
            <span class="fu-p-text">{{item.qcye}}</span>
            <span class="fu-p-content">期初</span>
          </div>
          <div class="fu-end">
            <span class="fu-p-text">{{item.qmye}}</span>
            <span class="fu-p-content">期末</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { Indicator } from 'mint-ui'
export default {
  name: 'funding',
  data: () => ({
    indicatorOkA: false,
    indicatorOkB: false,
    subjectName: '银行存款',
    subjectNo: 1002,
    isChange: false,
    chaged_selected_inv_time: '',
    dakai: false,
    shouru: '-',
    zhichu: '-',
    qcye: '-',
    qmye: '-',
    dqjffs: '-',
    dqdffs: '-',
    bnlj: '-',
    items: [],
    hasChild: false
  }),
  mounted () {
    window.WeixinJSBridge.call('hideOptionMenu')
  },
  created () {
    this.dakai = false
    Indicator.open('加载中...')
    axios.get('https://wechat.spiderair.com/api/funding', {
      params: {
        company_id: this.cid,
        start: this.selected_inv_time,
        selected_sub: this.subjectNo,
        selected_subname: this.subjectName
      }
    }).then(response => {
      this.indicatorOkA = true
      if (this.indicatorOkB) {
        Indicator.close()
        this.indicatorOkA = false
        this.indicatorOkB = false
      }
      const resData = response.data.data
      this.shouru = resData[0].dqjffs
      this.zhichu = resData[0].dqdffs
      this.bnlj = resData[0].qmye
    })
    axios.get('https://wechat.spiderair.com/api/detailedAccounts', {
      params: {
        company_id: this.cid,
        start: this.selected_inv_time,
        selected_sub: this.subjectNo,
        selected_subname: this.subjectName
      }
    }).then(response => {
      this.indicatorOkB = true
      if (this.indicatorOkA) {
        Indicator.close()
        this.indicatorOkA = false
        this.indicatorOkB = false
      }
      const resData = response.data.data
      this.qcye = resData.qcye
      this.qmye = resData.qmye
      if (resData.item_list != null) {
        this.items = resData.item_list.children
        typeof (this.items) !== 'undefined' && this.items.length > 0 ? this.hasChild = true : this.hasChild = false
      } else {
        this.hasChild = false
      }
    })
  },
  computed: {
    selected_inv_time () {
      if (!this.isChange) {
        return this.$store.state.selected_inv_time
      } else {
        return this.chaged_selected_inv_time
      }
    },
    cid () {
      return this.$store.state.company.id
    },
    cname () {
      return this.$store.state.company.name
    },
    dqqj () {
      return this.$store.state.dqqj
    },
    company_inv_times () {
      const tempInvtiems = this.$store.state.company.company_inv_times
      if (tempInvtiems[0] !== '取消') {
        tempInvtiems.unshift('取消')
      }
      return tempInvtiems
    }
  },
  methods: {
    change () {
      const that = this
      that.dakai = !that.dakai
    },
    testLayerFooter () {
      const that = this
      this.$layer.footer({
        content: '选择账期',
        btn: this.company_inv_times
      }).then(function (res) {
        Indicator.open('加载中...')
        var text = (res === 0 ? '取消' : '选项') + res
        console.log(text)
        that.chaged_selected_inv_time = that.company_inv_times[res]
        that.isChange = true
        axios.get('https://wechat.spiderair.com/api/funding', {
          params: {
            company_id: that.cid,
            start: that.chaged_selected_inv_time,
            selected_sub: that.subjectNo,
            selected_subname: that.subjectName
          }
        }).then(response => {
          that.indicatorOkA = true
          if (that.indicatorOkB) {
            Indicator.close()
            that.indicatorOkA = false
            that.indicatorOkB = false
          }
          const resData = response.data.data
          that.shouru = resData[0].dqjffs
          that.zhichu = resData[0].dqdffs
          that.bnlj = resData[0].qmye
        })
        axios.get('https://wechat.spiderair.com/api/detailedAccounts', {
          params: {
            company_id: that.cid,
            start: that.chaged_selected_inv_time,
            selected_sub: that.subjectNo,
            selected_subname: that.subjectName
          }
        }).then(response => {
          that.indicatorOkB = true
          if (that.indicatorOkA) {
            Indicator.close()
            that.indicatorOkA = false
            that.indicatorOkB = false
          }
          const resData = response.data.data
          that.qcye = resData.qcye
          that.qmye = resData.qmye
          if (resData.item_list != null) {
            that.items = resData.item_list.children
            typeof (that.items) !== 'undefined' && that.items.length > 0 ? that.hasChild = true : that.hasChild = false
          } else {
            that.hasChild = false
          }
        })
      })
    },
    deposit () {
      const that = this
      this.$layer.footer({
        content: '选择资金',
        btn: ['取消', '银行存款', '库存资金']
      }).then(function (res) {
        if (res !== 0) Indicator.open('加载中...')
        var text = (res === 0 ? '取消' : '选项') + res
        console.log(text)
        if (res === 2) {
          that.subjectName = '库存资金'
          that.subjectNo = 1001
        } else if (res === 1) {
          that.subjectName = '银行存款'
          that.subjectNo = 1002
        }
        let start = that.selected_inv_time
        if (that.isChange) {
          start = that.chaged_selected_inv_time
        }
        axios.get('https://wechat.spiderair.com/api/funding', {
          params: {
            company_id: that.cid,
            start: start,
            selected_sub: that.subjectNo,
            selected_subname: that.subjectName
          }
        }).then(response => {
          that.indicatorOkA = true
          if (that.indicatorOkB) {
            Indicator.close()
            that.indicatorOkA = false
            that.indicatorOkB = false
          }
          const resData = response.data.data
          that.shouru = resData[0].dqjffs
          that.zhichu = resData[0].dqdffs
          that.bnlj = resData[0].qmye
        })
        axios.get('https://wechat.spiderair.com/api/detailedAccounts', {
          params: {
            company_id: that.cid,
            start: start,
            selected_sub: that.subjectNo,
            selected_subname: that.subjectName
          }
        }).then(response => {
          that.indicatorOkB = true
          if (that.indicatorOkA) {
            Indicator.close()
            that.indicatorOkA = false
            that.indicatorOkB = false
          }
          const resData = response.data.data
          that.qcye = resData.qcye
          that.qmye = resData.qmye
          if (resData.item_list != null) {
            that.items = resData.item_list.children
            typeof (that.items) !== 'undefined' && that.items.length > 0 ? that.hasChild = true : that.hasChild = false
          } else {
            that.hasChild = false
          }
        })
      })
    }
  }
}
</script>

<style>
.fu-company {
  width: 100%;
  height: 37px;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 20px;
  border-top: 1px solid rgba(136, 136, 136, 0.22);
}
.fu-name {
  font-size: 13px;
  font-weight: 400;
  color: #081b3a;
  line-height: 37px;
}
.fu-data {
  font-size: 13px;
  font-weight: 400;
  color: #3180ff;
  line-height: 37px;
}
.fu-img {
  width: 13px;
  height: 11px;
  margin-left: 5px;
}

.fu-open {
  height: 179px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.fu-deposit {
  display: flex;
  flex-direction: row;
  height: 56px;
  align-items: center;
  justify-content: center;
}
.fu-d-text {
  font-size: 14px;
  font-weight: 400;
  color: #081b3a;
  line-height: 56px;
}
.fu-d-img {
  width: 14px;
  height: 12px;
  margin-left: 5px;
}
.fu-show {
  font-size: 29px;
  font-weight: 500;
  color: #3180ff;
}
.fu-prompt {
  font-size: 13px;
  font-weight: 400;
  color: rgba(8, 27, 58, 0.5);
}

.fu-sort {
  margin-top: 20px;
  height: 41px;
  display: flex;
  flex-direction: row;
  width: 100%;
}
.fu-directory {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fu-s-line {
  height: 100%;
  width: 1px;
  background: rgba(42, 126, 250, 0.5);
}
.fu-s-title {
  font-size: 14px;
  font-weight: 400;
  color: rgba(8, 27, 58, 0.8);
}
.fu-s-content {
  font-size: 12px;
  font-weight: 500;
  color: #2d2d2d;
}

.fu-display-i {
  width: 100%;
  height: 73px;
  background: #f4f5f8;
  border-top: 1px solid rgba(136, 136, 136, 0.22);
  padding: 5px 20px;
}
.fu-display {
  width: 100%;
  height: 73px;
  background: #ffffff;
  padding: 5px 20px;
}
.fu-d-heading {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 3px;
}
.fu-d-title {
  font-size: 16px;
  font-weight: 400;
  color: #081b3a;
}
.fu-d-css {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.fu-period {
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
}
.fu-end {
  display: flex;
  flex-direction: column;
  justify-content: right;
  text-align: right;
}
.fu-p-text {
  font-size: 14px;
  font-weight: 400;
  color: rgba(8, 27, 58, 0.8);
}
.fu-p-content {
  font-size: 12px;
  font-weight: 400;
  color: rgba(8, 27, 58, 0.5);
}
</style>
