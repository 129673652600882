<template>
  <div>
    <div class="backShort">
      <div style="display: flex;flex-direction: row;justify-content: space-between;">
        <div class="backShortTitle">
          <span class="textClass">江苏品博利科技有限公司</span>
        </div>
        <div class="backShortTitle2" @click="testLayerFooter()">
          <span class="textClass">2020年4月</span>
          <img src="/img/home_page_drop_down_box_btn.png" style="width:13px;height:8px;margin-top:4px;margin-left:5px;">
        </div>
      </div>
    </div>
    <div class="examine">
      <div class="select" @click="taxOptions()">
        <span class="e-text">增值税</span>
        <img src="/img/qietu/pay_taxes_drop_down_btn.png"
          style="width:13px;height:12px;margin-top:4px;margin-left:5px;">
      </div>
      <!-- <div class="echart"></div> -->
    </div>
    <div class="display-line">
      <div class="dl-row">
        <span class="dl-title">本期销项税额</span>
        <span class="dl-title">5.10</span>
      </div>
      <div class="dividing"></div>
      <div class="dl-row">
        <span class="dl-title">本期进项税额</span>
        <span class="dl-title">3.40</span>
      </div>
      <div class="dividing"></div>
      <div class="dl-row">
        <span class="dl-title">本期进项税额转出</span>
        <span class="dl-title">170.00</span>
      </div>
      <div class="dividing"></div>
      <div class="dl-row">
        <span class="dl-title">本期应交增值税</span>
        <span class="dl-title">11.90</span>
      </div>
      <div class="dividing"></div>
      <div class="dl-row">
        <span class="dl-title">本期留抵税额</span>
        <span class="dl-title">11.90</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'tax',
  data: () => ({

  }),
  methods: {
    testLayerFooter () {
      this.$layer.footer({
        content: '选择账期',
        btn: ['取消', '2019年07月', '2019年07月', '2019年07月', '2019年07月', '2019年07月', '2019年07月', '2019年07月', '2019年07月', '2019年07月', '2019年07月', '2019年07月', '2019年07月', '2019年07月', '2019年07月', '2019年07月']
      }).then(function (res) {
        var text = (res === 0 ? '取消' : '选项') + res
        console.log(text)
      })
    },
    taxOptions () {
      this.$layer.footer({
        content: '选择税收',
        btn: ['取消', '增值税', '附加税', '印花税', '企业所得税']
      }).then(function (res) {
        var text = (res === 0 ? '取消' : '选项') + res
        console.log(text)
      })
    }
  }
}
</script>

<style>
.company {
  width: 100%;
  height: 37px;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 20px;
  border-top: 1px solid rgba(136, 136, 136, 0.22);
}
.name {
  font-size: 13px;
  font-weight: 400;
  color: #081b3a;
  line-height: 37px;
}
.data {
  font-size: 13px;
  font-weight: 400;
  color: #3180ff;
  line-height: 37px;
}
.img {
  width: 13px;
  height: 11px;
  margin-left: 5px;
}

.examine {
  width: 100%;
  /* height: 255px; */
}
.select {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 23px;
}
.e-text {
  font-size: 14px;
  font-weight: 400;
  color: #081b3a;
}
.echart {
  width: 90%;
}

.display-line {
  margin-top: 20px;
  width: 100%;
  height: 203px;
  background: #ffffff;
}
.dl-row {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
}
.dl-title {
  font-size: 14px;
  font-weight: 400;
  color: rgba(8, 27, 58, 0.8);
}
.dividing {
  border-top: 1px solid rgba(136, 136, 136, 0.22);
  width: 100%;
  height: 1px;
}
</style>
