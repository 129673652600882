<template>
  <div>
    <div class="backShort">
      <div style="display: flex;flex-direction: row;justify-content: space-between;">
        <div class="backShortTitle">
          <span class="textClass">{{cname}}</span>
        </div>
        <div class="backShortTitle2" @click="testLayerFooter()">
          <span class="textClass">{{selected_invoice_inv_times}}</span>
          <img src="/img/home_page_drop_down_box_btn.png" style="width:13px;height:8px;margin-top:4px;margin-left:5px;">
        </div>
      </div>
    </div>
    <div class="frame">
      <div class="box">
        <span class="title">发票数量</span>
        <span class="content">{{totalnum}}</span>
      </div>
      <div class="box">
        <span class="title">总金额</span>
        <span class="content">{{jesum}}</span>
      </div>
      <div class="box">
        <span class="title">总税额</span>
        <span class="content">{{sesum}}</span>
      </div>
    </div>
    <div>
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <van-cell class="vanInvoice" v-for="item in list" :key="item.id">
          <span class="i-title">{{item.gfmc}}</span>
          <div class="i-line"></div>
          <div class="text">
            <span>价税合计：{{item.amount}}</span>
          </div>
          <div style="display: flex;flex-direction: column;">
            <div style="display: flex;flex-direction: row;margin-bottom:3px;">
              <div class="i-text">
                <span>开票日期：{{item.kprq}}</span>
              </div>
              <div class="i-text" style="width:100px;">
                <span>金额：{{item.je}}</span>
              </div>
              <div class="i-text">
                <span>税额：{{item.se}}</span>
              </div>
            </div>
            <div style="width:100%;display: flex;flex-direction: row;">
              <div class="i-text">
                <span>发票代码：{{item.fpdm}}</span>
              </div>
              <div class="i-text">
                <span>发票号码：{{item.fphm}}</span>
              </div>
            </div>
          </div>
        </van-cell>
      </van-list>
    </div>
    <img v-if="btnFlag" class="go-top" src="/img/qietu/set_top_btn.png" @click="backTop"
         style="position:fixed;right:20px;bottom:30px;width:50px;height:50px;">
  </div>
</template>

<script>
import axios from 'axios'
import { Indicator } from 'mint-ui'

export default {
  name: 'outPutTest',
  data: () => ({
    invTimeIsChange: false,
    jesum: '-',
    sesum: '-',
    totalnum: '-',
    items: [],
    loading: false,
    finished: false,
    list: [],
    page: 0,
    btnFlag: false,
    scrollTop: 0
  }),
  computed: {
    selected_invoice_inv_times () {
      if (this.$store.state.company.should_contain_current_inv && !this.invTimeIsChange) {
        return this.$store.state.selected_inv_time
      }
      return this.$store.state.selected_invoice_inv_times
    },
    invoice_inv_times () {
      const tempInvoiceInvtimes = this.$store.state.company.invoice_inv_times
      console.log(tempInvoiceInvtimes)
      if (tempInvoiceInvtimes[0] !== '取消') {
        if (this.$store.state.company.should_contain_current_inv) {
          tempInvoiceInvtimes.unshift(this.$store.state.selected_inv_time)
        }
        tempInvoiceInvtimes.unshift('取消')
      }
      return tempInvoiceInvtimes
    },
    cname () {
      return this.$store.state.company.name
    },
    cid () {
      return this.$store.state.company.id
    }
  },
  mounted () {
    window.WeixinJSBridge.call('hideOptionMenu')
    window.addEventListener('scroll', this.scrollToTop)
  },
  destroyed () {
    window.removeEventListener('scroll', this.scrollToTop)
  },
  created () {
    // Indicator.open('加载中...')
    const that = this
    axios.get('https://wechat.spiderair.com/api/invoiceScanListStatistics', {
      params: {
        company_id: this.cid,
        zq: this.selected_invoice_inv_times,
        kind: 1,
        is_not_reach: 0,
        page: 1
      }
    }).then(response => {
      that.jesum = response.data.data.jesum
      that.sesum = response.data.data.sesum
      that.totalnum = response.data.data.totalnum
    })
  },
  methods: {
    backTop () {
      const that = this
      const timer = setInterval(() => {
        console.log('时间定时器')
        const ispeed = Math.floor(-that.scrollTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
        if (that.scrollTop === 0) {
          clearInterval(timer)
        }
      }, 16)
    },
    scrollToTop () {
      const that = this
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      that.scrollTop = scrollTop
      if (that.scrollTop > 100) {
        that.btnFlag = true
      } else {
        that.btnFlag = false
      }
    },
    onLoad () {
      this.page++
      axios.get('https://wechat.spiderair.com/api/invoiceScanList', {
        params: {
          company_id: this.cid,
          zq: this.selected_invoice_inv_times,
          kind: 1,
          is_not_reach: 0,
          page: this.page
        }
      }).then(response => {
        const resData = response.data.data.data
        for (const dataKey in resData) {
          this.list.push(resData[dataKey])
        }
        // 加载状态结束
        this.loading = false
        // // 数据全部加载完成
        if (resData.length === 0) {
          this.finished = true
        }
      })
    },
    testLayerFooter () {
      const that = this
      this.$layer.footer({
        content: '选择账期',
        btn: this.invoice_inv_times
      }).then(function (res) {
        if (res !== 0) {
          that.page = 1
          that.loading = false
          that.finished = false
          Indicator.open('加载中...')
          that.invTimeIsChange = true
        } else {
          console.log(res)
          return false
        }
        console.log(that.invoice_inv_times[res])
        that.$store.commit('switchinvoiceInvTime', res)
        axios.get('https://wechat.spiderair.com/api/invoiceScanListStatistics', {
          params: {
            company_id: that.cid,
            zq: that.selected_invoice_inv_times,
            kind: 1,
            is_not_reach: 0,
            page: 1
          }
        }).then(response => {
          that.jesum = response.data.data.jesum
          that.sesum = response.data.data.sesum
          that.totalnum = response.data.data.totalnum
        })
        axios.get('https://wechat.spiderair.com/api/invoiceScanList', {
          params: {
            company_id: that.cid,
            zq: that.invoice_inv_times[res],
            kind: 1,
            is_not_reach: 0,
            page: that.page
          }
        }).then(response => {
          that.list = []
          const resData = response.data.data.data
          for (const dataKey in resData) {
            that.list.push(resData[dataKey])
          }
          Indicator.close()
          // 加载状态结束
          that.loading = false
          // // 数据全部加载完成
          if (resData.length === 0) {
            that.finished = true
          }
        })
      })
    }
  }
}
</script>

<style>
.layui-m-layer-footer .layui-m-layerbtn {
  overflow: auto;
  max-height: 300px !important;
}

.frame {
  width: 100%;
  height: 87px;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.box {
  width: 103px;
  height: 67px;
  background: #ffffff;
  margin-top: 20px;
  border-radius: 12px;
  box-shadow: 0px 0px 38px 0px rgba(49, 128, 255, 0.08);
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 12px;
  font-weight: 400;
  color: #081b3a;
}

.content {
  margin-top: 7px;
  font-size: 15px;
  font-weight: 500;
  color: #21509b;
}

.vanInvoice {
  margin: 0 auto;
  width: 94% !important;
  height: 100px;
  background: #ffffff;
  text-align: left;
  border-radius: 12px;
  box-shadow: 0px 0px 38px 0px rgba(49, 128, 255, 0.08);
  /*padding: 10px 10px 0px 10px;*/
  margin-bottom: 10px;
}

.i-title {
  font-size: 14px;
  font-weight: 400;
  color: #081b3a;
}

.i-line {
  width: 100%;
  height: 1px;
  border: 1px dashed #888888;
  margin-top: 0px !important;
  margin-bottom: 3px;
}

.text {
  font-size: 13px;
  font-weight: 400;
  color: rgba(8, 27, 58, 0.8);
  margin-bottom: 3px;
  line-height: 22px;
}

.i-text {
  font-size: 11px;
  font-weight: 400;
  color: rgba(8, 27, 58, 0.6);
  width: 140px;
  display: flex;
  flex-direction: row;
  line-height: 12px;
}
</style>
