import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import 'vant/lib/index.css'
import './plugins/vant.js'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'vue-layer-mobile/need/layer.css'
import layer from 'vue-layer-mobile'
import MintUI from 'mint-ui'
import 'mint-ui/lib/style.css'
import qs from 'qs'
Vue.use(MintUI)
Vue.use(layer)
Vue.use(ElementUI)

Vue.prototype.$qs = qs
Vue.config.productionTip = true
window.axios = require('axios')

new Vue({
  router,
  store,
  axios,
  components: {
    App
  },
  render: h => h(App)
}).$mount('#app')
