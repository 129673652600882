<template>
  <van-pull-refresh v-model="pullLoading" @refresh="onPullDownRefresh">
    <div class="promptIn2">
      <span class="prompt1">温馨提示：</span>
      <div class="promptIn1">
        <div>
          <span>本月勾选认证截止日期为</span>
          <span class="prompt1">{{ statisticsDetail.out_date || '-' }}</span>
          <span>号</span>
        </div>
        <div>
          <span>目前已认证</span>
          <span class="prompt1">{{ statisticsDetail.renzheng_count || 0 }}</span>
          <span>张，税额</span>
          <span class="prompt1">{{ statisticsDetail.renzheng_se || 0 }}</span>
          <span>元</span>
        </div>
        <div>
          <span>目前已勾选</span>
          <span class="prompt1">{{ statisticsDetail.gouxuan_count || 0 }}</span>
          <span>张，税额</span>
          <span class="prompt1">{{ statisticsDetail.gouxuan_se || 0 }}</span>
          <span>元</span>
        </div>
        <div>
          <span>未勾选</span>
          <span class="prompt1">{{ statisticsDetail.weida_count || 0 }}</span>
          <span>张，税额</span>
          <span class="prompt1">{{ statisticsDetail.weida_se || 0 }}</span>
          <span>元</span>
        </div>
      </div>
    </div>

    <div class="frame">
      <div class="box">
        <span class="title">发票数量</span>
        <span class="content">{{ counterStatistics.totalnum || 0 }}</span>
      </div>
      <div class="box">
        <span class="title">总金额</span>
        <span class="content">{{ counterStatistics.jesum || 0 }}</span>
      </div>
      <div class="box" style="border-right: 0px #fff">
        <span class="title">总税额</span>
        <span class="content">{{ counterStatistics.sesum || 0 }}</span>
      </div>
    </div>
    <!-- 在这里新增一个搜索框框 -->
    <div class="search-form">
      <van-cell title="选择日期区间" :value="date" @click="show = true" />
      <van-calendar v-model="show" :default-date="defaultDate" type="range" :min-date="minDate" @confirm="onConfirm" />
    </div>

    <!-- 列表区域 -->
    <div style="margin-top: 10px">
      <div style="display: flex; flex-direction: row; justify-content: space-between; padding: 0px 10px; margin-bottom: 10px">
        <van-button style="height: 30px; width: 60px" plain hairline type="info" @click="checkAll">全选</van-button>
        <van-button style="height: 30px; width: 100px" plain hairline type="primary" @click="toggleAll">提交认证</van-button>
      </div>
      <!-- 当数据尚未请求完成时 -->
      <van-loading color="#0094ff" v-if="loading" vertical>加载中...</van-loading>

      <!-- 当列表数据请求完成时 -->
      <div v-else>
        <!-- 列表有数据 -->
        <van-list v-if="list.length !== 0" v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
          <van-checkbox-group v-model="result" ref="checkboxGroup">
            <van-cell class="vanInvoice1" v-for="(item, index) in list" :key="item.id">
              <span class="i-title">{{ item.xfmc }}</span>
              <div class="i-line"></div>
              <div class="text">
                <span>价税合计：{{ item.amount }} {{ item.fplx }}</span>
              </div>
              <div style="display: flex; flex-direction: column">
                <div style="display: flex; flex-direction: row; margin-bottom: 3px">
                  <div class="i-text">
                    <span>开票日期：{{ item.kprq }}</span>
                  </div>
                  <div class="i-text" style="width: 100px">
                    <span>金额：{{ item.je }}</span>
                  </div>
                  <div class="i-text">
                    <span>税额：{{ item.se }}</span>
                  </div>
                </div>
                <div style="width: 100%; display: flex; flex-direction: row">
                  <div class="i-text">
                    <span>发票代码：{{ item.fpdm }}</span>
                  </div>
                  <div class="i-text">
                    <span>发票号码：{{ item.fphm }}</span>
                  </div>
                </div>
              </div>
              <van-checkbox class="suspension" shape="square" :name="index" :disabled="item.disable"></van-checkbox>
            </van-cell>
          </van-checkbox-group>
        </van-list>
        <!-- 列表空状态 -->
        <van-empty v-else description="暂无数据" />
      </div>
    </div>
    <img
      v-if="btnFlag"
      class="go-top"
      src="/img/qietu/set_top_btn.png"
      @click="backTop"
      style="position: fixed; right: 20px; bottom: 30px; width: 50px; height: 50px"
    />
  </van-pull-refresh>
</template>

<script>
import axios from 'axios'
import { Toast, Dialog } from 'vant'
import { Indicator } from 'mint-ui'

export default {
  name: 'income',
  data() {
    return {
      isCheckedAll: false,
      loading: false,
      pullLoading: false,
      finished: false,
      list: [],
      page: 1,
      btnFlag: false,
      scrollTop: 0,
      result: [],
      disable: false,
      expired: 1, // 是否过期
      ensure: 1,
      identify: 2,
      // 最顶部的发票认证统计
      statisticsDetail: {
        out_date: '', // 截至认证日期
        renzheng_se: '', // 认证税额
        renzheng_count: '', // 认证张数
        gouxuan_se: '', // 已勾选税额
        gouxuan_count: '', //已勾选张数
        weida_se: '', // 未勾选税额
        weida_count: '' // 未勾选张数
      },
      // 发票张数，总金额，总税额 (列表统计)
      counterStatistics: {
        jesum: '-',
        sesum: '-',
        totalnum: '-'
      },

      // 新增日期区间选择
      rangeDate: {
        kprq_start: undefined,
        kprq_end: undefined
      },
      date: '', // 仅用于页面展示
      defaultDate:new Date(),// 默认日期面板的选中值
      minDate: new Date(2018, 0, 1),
      show: false
    }
  },
  created() {
    this.initCompanyCheckDetail()
    this.initCompanyWeidaInoviceStatistics()
    this.initWeidaList()
  },
  mounted() {
    if (window.history && window.history.pushState) {
      // 向历史记录中插入了当前页
      history.pushState(null, null, document.URL)
      window.addEventListener('popstate', this.goBack, false)
    }
    window.addEventListener('scroll', this.scrollToTop)
    document.addEventListener('WeixinJSBridgeReady', function onBridgeReady() {
      window.WeixinJSBridge.call('hideOptionMenu')
    })
  },
  destroyed() {
    window.removeEventListener('popstate', this.goBack, false)
    window.removeEventListener('scroll', this.scrollToTop)
  },
  methods: {
    // 查询公司发票认证统计
    initCompanyCheckDetail() {
      axios
        .get('https://wechat.spiderair.com/api/invoiceCheckStatistics', {
          params: {
            company_id: this.$route.query.cid,
            zq: this.$route.query.zq
          }
        })
        .then(response => {
          this.statisticsDetail = response.data.data
        })
        .catch(() => {
          this.statisticsDetail = {}
        })
    },
    // 查询公司未达发票列表统计
    initCompanyWeidaInoviceStatistics() {
      axios
        .get('https://wechat.spiderair.com/api/invoiceScanWeidaListStatistics', {
          params: {
            company_id: this.$route.query.cid,
            zq: this.$route.query.zq,
            kind: 2,
            is_not_reach: 1,
            page: this.page
          }
        })
        .then(response => {
          this.counterStatistics = response.data.data
        })
        .catch(() => {
          this.counterStatistics = {}
        })
    },
    // 查询未达发票列表
    async initWeidaList() {
      this.loading = true

      const response = await axios.get('https://wechat.spiderair.com/api/weidaForLackList', {
        params: {
          company_id: this.$route.query.cid,
          zq: this.$route.query.zq,
          kind: 2,
          is_not_reach: 1,
          page: this.page,
          ...this.rangeDate
        }
      })
      if (response.data.status_code === 200) {
        const identifyData = response.data.data
        const resData = response.data.data.data.data

        // test start
        this.expired = identifyData.expired // raw
        this.ensure = identifyData.ensure // raw
        this.identify = identifyData.identify // raw
        // this.expired = 0
        // this.ensure = 0
        // this.identify = 0
        // test end

        let temp = []

        for (const dataKey in resData) {
          // test start
          resData[dataKey].disable = true // raw
          // resData[dataKey].disable = false
          // test end
          if (!identifyData.disable && resData[dataKey].fplx !== '通行费电子发票') {
            resData[dataKey].disable = false
          }
          temp.push(resData[dataKey])
        }
        this.list = [...this.list,...temp]
        
        // 加载状态结束
        this.loading = false
        // // 数据全部加载完成
        if (resData.length === 0 || resData.length < 10) {
          this.finished = true
        }
      } else {
        this.finished = true
        this.loading = false
        Indicator.close()
        Toast(`状态码：${response.status_code}，消息:${response.message}`)
      }

      // console.log(response);
    },
    // 查询公司认证情况 (暂未使用)
    initRenzhengDetail() {
      // axios.get('https://wechat.spiderair.com/api/indentifyStatus', {
      //   params: {
      //     company_id: this.$route.query.cid,
      //     zq: this.$route.query.zq
      //   }
      // }).then(response => {
      //   console.log('created first!')
      //   // that.disable = false
      //   that.disable = response.data.data.disable
      //   that.identify = response.data.data.identify
      //   that.expired = response.data.data.expired
      // })
    },
    // 点击了浏览器的返回按钮
    goBack() {
      console.log('点击了浏览器的返回按钮')
      sessionStorage.clear()
      // window.history.back()
      window.WeixinJSBridge.call('closeWindow')
    },
    // 全选
    checkAll() {
      if (this.list.length === 0) {
        Toast.fail('暂无可操作的未达发票')
        return false
      }
      if (this.identify !== 1) {
        if (this.identify === 3) {
          Toast.fail('贵公司当期申报工作已发起')
          return false
        } else if (this.identify === 2) {
          Toast.fail('贵公司当期已完成认证')
          return false
        } else if (this.identify === 4) {
          Toast.fail('贵公司当期认证工作已发起')
          return false
        }
      }

      if (this.expired === 1) {
        Toast.fail('本月勾选认证已过截止时间')
        return false
      }
      if (this.ensure) {
        Toast.fail('贵公司已确认不进行勾选')
        return false
      }
      if (this.isCheckedAll) {
        this.$refs.checkboxGroup.toggleAll({
          checked: false,
          skipDisabled: true
        })
        this.isCheckedAll = false
      } else {
        this.$refs.checkboxGroup.toggleAll({
          checked: true,
          skipDisabled: true
        })
        this.isCheckedAll = true
      }
    },
    // 提交认证
    toggleAll() {
      const that = this
      if (this.identify !== 1) {
        switch (this.identify) {
          case 2:
            Toast.fail('贵公司当期认已完成认证')
            break
          case 3:
            Toast.fail('贵公司当期申报工作已发起')
            break
          case 4:
            Toast.fail('贵公司当期认证工作已发起')
            break
          default:
            Toast.fail('程序异常，请联系系统管理员~')
            break
        }
      }
      if (this.expired === 1) return Toast.fail('本月勾选认证已过截止时间')
      if (this.ensure) return Toast.fail('贵公司已确认不进行勾选')
      const result = this.result
      let ids = ''
      let jes = 0
      let ses = 0
      let count = 0
      for (const resultKey in result) {
        // ids = ids + result[resultKey] + ','
        ids = ids + that.list[result[resultKey]].id + ','
        jes += Number(that.list[result[resultKey]].je)
        ses += Number(that.list[result[resultKey]].se)
        count++
      }
      // console.log(ids)
      if (ids === '') {
        console.log(that.list)
        if (that.list.length === 0) {
          Toast.fail('暂无可操作的未达发票')
          return false
        }
        const message = '我司认可按照已送达的纸质进项发票在当期认证，未达发票不参与本期认证，并将优先申报纳税。'
        Dialog.confirm({
          title: '提示',
          message: message
        })
          .then(() => {
            console.log('user confirm')
            Indicator.open('加载中...')
            // 缺票勾选操作
            axios
              .post('https://wechat.spiderair.com/api/ensureNotCheck', {
                zq: this.$route.query.zq,
                company_id: this.$route.query.cid
              })
              .then(response => {
                Indicator.close()
                this.$refs.checkboxGroup.toggleAll(false)
                Toast.success(response.data.message)
                this.page = 1
                axios
                  .get('https://wechat.spiderair.com/api/weidaForLackList', {
                    params: {
                      company_id: this.$route.query.cid,
                      zq: this.$route.query.zq,
                      kind: 2,
                      is_not_reach: 1,
                      page: this.page
                    }
                  })
                  .then(response => {
                    this.list = []
                    const identifyData = response.data.data
                    const resData = response.data.data.data.data
                    this.expired = identifyData.expired
                    this.ensure = identifyData.ensure
                    this.identify = identifyData.identify
                    for (const dataKey in resData) {
                      resData[dataKey].disable = true
                      if (!identifyData.disable && resData[dataKey].fplx !== '通行费电子发票') {
                        resData[dataKey].disable = false
                      }
                      this.list.push(resData[dataKey])
                    }
                    // 加载状态结束
                    this.loading = false
                    Toast.success(response.data.message)
                    // 查询公司发票勾选情况
                    axios
                      .get('https://wechat.spiderair.com/api/invoiceCheckStatistics', {
                        params: {
                          company_id: this.$route.query.cid,
                          zq: this.$route.query.zq
                        }
                      })
                      .then(response => {
                        this.renzheng_se = response.data.data.renzheng_se
                        this.renzheng_count = response.data.data.renzheng_count
                        this.gouxuan_se = response.data.data.gouxuan_se
                        this.gouxuan_count = response.data.data.gouxuan_count
                        this.weida_se = response.data.data.weida_se
                        this.weida_count = response.data.data.weida_count
                        this.out_date = response.data.data.out_date
                      })
                      .catch(function() {
                        that.renzheng_se = ''
                        that.renzheng_count = ''
                        that.gouxuan_se = ''
                        that.gouxuan_count = ''
                        that.weida_se = ''
                        that.weida_count = ''
                        that.out_date = ''
                      })
                    // 查询公司未达发票列表
                    axios
                      .get('https://wechat.spiderair.com/api/invoiceScanWeidaListStatistics', {
                        params: {
                          company_id: this.$route.query.cid,
                          zq: this.$route.query.zq,
                          kind: 2,
                          is_not_reach: 1,
                          page: 1
                        }
                      })
                      .then(response => {
                        this.jesum = response.data.data.jesum
                        this.sesum = response.data.data.sesum
                        this.totalnum = response.data.data.totalnum
                      })
                      .catch(function() {
                        that.jesum = ''
                        that.sesum = ''
                        that.totalnum = ''
                      })
                    // // 数据全部加载完成
                    if (resData.length === 0 || resData.length < 10) {
                      this.finished = true
                    }
                  })
                  .catch(function(error) {
                    that.finished = true
                    Indicator.close()
                    if (error.response) {
                      // The request was made and the server responded with a status code
                      // that falls out of the range of 2xx
                      Toast(error.response.data.message)
                      // console.log(error.response.status)
                      // console.log(error.response.headers)
                    } else {
                      // Something happened in setting up the request that triggered an Error
                      Toast('Error', error.message)
                    }
                    console.log(error.config)
                  })
              })
          })
          .catch(() => {
            console.log('user cancel')
          })
        return false
      }
      jes = jes.toFixed(2)
      ses = ses.toFixed(2)
      const message =
        '本次勾选：' + count + '份，金额合计' + jes + '，税额合计' + ses + '。请确认以上勾选发票贵公司已经收到，并将尽快送达我处，该操作不可撤销，请谨慎使用。'
      Dialog.confirm({
        title: '提示',
        message: message
      })
        .then(() => {
          console.log('user confirm')
          Indicator.open('加载中...')
          // 缺票勾选操作
          axios
            .post('https://wechat.spiderair.com/api/lackInvoiceCheck2', {
              ids: ids,
              uid:this.$route.query.uid
            })
            .then(response => {
              Indicator.close()
              this.$refs.checkboxGroup.toggleAll(false)
              Toast.success(response.data.message)
              this.page = 1
              axios
                .get('https://wechat.spiderair.com/api/weidaForLackList', {
                  params: {
                    company_id: this.$route.query.cid,
                    zq: this.$route.query.zq,
                    kind: 2,
                    is_not_reach: 1,
                    page: this.page,
                    uid:this.$route.query.uid
                  }
                })
                .then(response => {
                  this.list = []
                  const identifyData = response.data.data
                  const resData = response.data.data.data.data
                  // test start
                  this.expired = identifyData.expired // raw
                  this.ensure = identifyData.ensure // raw
                  this.identify = identifyData.identify // raw
                  // this.expired = 0
                  // this.ensure = 0
                  // this.identify = 0
                  // test end
                  for (const dataKey in resData) {
                    // test start
                    resData[dataKey].disable = true // raw
                    // resData[dataKey].disable = false
                    // test end
                    if (!identifyData.disable && resData[dataKey].fplx !== '通行费电子发票') {
                      resData[dataKey].disable = false
                    }
                    this.list.push(resData[dataKey])
                  }
                  // 加载状态结束
                  this.loading = false
                  Toast.success(response.data.message)
                  // 查询公司发票勾选情况
                  axios
                    .get('https://wechat.spiderair.com/api/invoiceCheckStatistics', {
                      params: {
                        company_id: this.$route.query.cid,
                        zq: this.$route.query.zq
                      }
                    })
                    .then(response => {
                      this.renzheng_se = response.data.data.renzheng_se
                      this.renzheng_count = response.data.data.renzheng_count
                      this.gouxuan_se = response.data.data.gouxuan_se
                      this.gouxuan_count = response.data.data.gouxuan_count
                      this.weida_se = response.data.data.weida_se
                      this.weida_count = response.data.data.weida_count
                      this.out_date = response.data.data.out_date
                    })
                  // 查询公司未达发票列表
                  axios
                    .get('https://wechat.spiderair.com/api/invoiceScanWeidaListStatistics', {
                      params: {
                        company_id: this.$route.query.cid,
                        zq: this.$route.query.zq,
                        kind: 2,
                        is_not_reach: 1,
                        page: 1
                      }
                    })
                    .then(response => {
                      this.jesum = response.data.data.jesum
                      this.sesum = response.data.data.sesum
                      this.totalnum = response.data.data.totalnum
                    })
                  // // 数据全部加载完成
                  if (resData.length === 0 || resData.length < 10) {
                    this.finished = true
                  }
                })
                .catch(function(error) {
                  that.finished = true
                  Indicator.close()
                  if (typeof error.response.data.errors !== 'undefined') {
                    const errs = error.response.data.errors
                    for (const err in errs) {
                      Toast(errs[err][0])
                    }
                  } else if (typeof error.response.data.message !== 'undefined') {
                    Toast(error.response.data.message)
                  }
                })
            })
            .catch(function(error) {
              Indicator.close()
              if (typeof error.response.data.errors !== 'undefined') {
                const errs = error.response.data.errors
                for (const err in errs) {
                  Toast(errs[err][0])
                }
              } else if (typeof error.response.data.message !== 'undefined') {
                Toast(error.response.data.message)
              }
              this.$refs.checkboxGroup.toggleAll(false)
              this.page = 1
              axios
                .get('https://wechat.spiderair.com/api/weidaForLackList', {
                  params: {
                    company_id: this.$route.query.cid,
                    zq: this.$route.query.zq,
                    kind: 2,
                    is_not_reach: 1,
                    page: this.page
                  }
                })
                .then(response => {
                  this.list = []
                  const identifyData = response.data.data
                  const resData = response.data.data.data.data
                  // test start
                  this.expired = identifyData.expired // raw
                  this.ensure = identifyData.ensure // raw
                  this.identify = identifyData.identify // raw
                  // this.expired = 0
                  // this.ensure = 0
                  // this.identify = 0
                  // test end
                  for (const dataKey in resData) {
                    // test start
                    resData[dataKey].disable = true // raw
                    // resData[dataKey].disable = false
                    // test end
                    if (!identifyData.disable && resData[dataKey].fplx !== '通行费电子发票') {
                      resData[dataKey].disable = false
                    }
                    this.list.push(resData[dataKey])
                  }
                  // 加载状态结束
                  this.loading = false
                  Toast.success(response.data.message)
                  // 查询公司发票勾选情况
                  axios
                    .get('https://wechat.spiderair.com/api/invoiceCheckStatistics', {
                      params: {
                        company_id: this.$route.query.cid,
                        zq: this.$route.query.zq
                      }
                    })
                    .then(response => {
                      this.renzheng_se = response.data.data.renzheng_se
                      this.renzheng_count = response.data.data.renzheng_count
                      this.gouxuan_se = response.data.data.gouxuan_se
                      this.gouxuan_count = response.data.data.gouxuan_count
                      this.weida_se = response.data.data.weida_se
                      this.weida_count = response.data.data.weida_count
                      this.out_date = response.data.data.out_date
                    })
                  // 查询公司未达发票列表
                  axios
                    .get('https://wechat.spiderair.com/api/invoiceScanWeidaListStatistics', {
                      params: {
                        company_id: this.$route.query.cid,
                        zq: this.$route.query.zq,
                        kind: 2,
                        is_not_reach: 1,
                        page: 1
                      }
                    })
                    .then(response => {
                      this.jesum = response.data.data.jesum
                      this.sesum = response.data.data.sesum
                      this.totalnum = response.data.data.totalnum
                    })
                  // // 数据全部加载完成
                  if (resData.length === 0 || resData.length < 10) {
                    this.finished = true
                  }
                })
                .catch(function(error) {
                  that.finished = true
                  Indicator.close()
                  if (typeof error.response.data.errors !== 'undefined') {
                    const errs = error.response.data.errors
                    for (const err in errs) {
                      Toast(errs[err][0])
                    }
                  } else if (typeof error.response.data.message !== 'undefined') {
                    Toast(error.response.data.message)
                  }
                })
            })
        })
        .catch(() => {
          console.log('user cancel')
        })
    },
    // 返回顶部
    backTop() {
      const that = this
      const timer = setInterval(() => {
        const ispeed = Math.floor(-that.scrollTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
        if (that.scrollTop === 0) {
          clearInterval(timer)
        }
      }, 16)
    },
    // 滚动到顶部
    scrollToTop() {
      const that = this
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      that.scrollTop = scrollTop
      if (that.scrollTop > 100) {
        that.btnFlag = true
      } else {
        that.btnFlag = false
      }
    },
    // 加载列表数据 (分页)
    onLoad() {
      this.page++
      this.initWeidaList()
    },
    // 格式化日期
    formatDate(date) {
      return `${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}`
    },
    // 点击日期选择器的确定按钮
    onConfirm(date) {
      const [start, end] = date
      this.show = false
      this.date = `${this.formatDate(start)} - ${this.formatDate(end)}`
      this.rangeDate.kprq_start = this.formatDate(start)
      this.rangeDate.kprq_end = this.formatDate(end)
      this.page = 1
      this.initWeidaList()
    },
    // 下拉刷新
    onPullDownRefresh() {
      this.page = 1
      this.rangeDate = {}
      this.date = ''
      this.list = []
      this.defaultDate = new Date()
      this.pullLoading = true
      this.initCompanyCheckDetail()
      this.initCompanyWeidaInoviceStatistics()
      this.initWeidaList().then(() => (this.pullLoading = false))
    }
  }
}
</script>

<style lang="css" scoped>
.img {
  width: 14px;
  height: 12px;
}

.frame {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 57px;
}

.frame .box {
  width: 33.3%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #ffffff;
  border-radius: 0px;
  border-right: 1px dashed #88888877;
}

.frame .box .title {
  font-size: 12px;
  font-weight: 400;
  color: #081b3a;
}

.frame .box .content {
  margin-top: 7px;
  font-size: 15px;
  font-weight: 500;
  color: #21509b;
}

.search-form {
  margin-top: 20px;
  /* background-color: red; */
  /* border: 1px solid #000; */
}

.vanInvoice1 {
  margin: 0 auto;
  width: 94% !important;
  height: 100px;
  background: #ffffff;
  text-align: left;
  border-radius: 12px;
  box-shadow: 0px 0px 38px 0px rgba(49, 128, 255, 0.08);
  padding: 6px 10px 0px 10px !important;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
}

.vanInvoice1 .suspension {
  position: relative;
  top: -80px;
  left: 280px;
}

.i-title {
  font-size: 14px;
  font-weight: 400;
  color: #081b3a;
}

.i-line {
  width: 100%;
  height: 1px;
  border: 1px dashed #888888;
  margin-top: 0px !important;
  margin-bottom: 3px;
}

.text {
  font-size: 13px;
  font-weight: 400;
  color: rgba(8, 27, 58, 0.8);
  margin-bottom: 3px;
  line-height: 22px;
}

.i-text {
  font-size: 11px;
  font-weight: 400;
  color: rgba(8, 27, 58, 0.6);
  width: 140px;
  display: flex;
  flex-direction: row;
  line-height: 12px;
}

.loading {
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.promptIn2 {
  width: 100%;
  height: 80px;
  background-image: url('/img/home_page_small_bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 7px;
}

.promptIn2 .prompt1 {
  font-size: 12px;
  font-weight: bold;
  /* color: rgb(255, 255, 255); */
  color: #e0b231;
  letter-spacing: 2px;
}

.promptIn2 .promptIn1 {
  font-size: 12px;
  font-weight: 400;
  /* color: #e0b231; */
  color: rgb(255, 255, 255);
  letter-spacing: 2px;
  text-align: left;
  justify-content: center;
  align-items: center;
}
</style>
