import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import WxAuth from '../views/WxAuth.vue'
import Whole from '../views/whole.vue'
import Output from '../views/outPut.vue'
import Income from '../views/income.vue'
import Funding from '../views/funding.vue'
import Statement from '../views/statement.vue'
import tax from '../views/tax.vue'
import payable from '../views/payable.vue'
import receivable from '../views/receivable.vue'
import profit from '../views/profit.vue'
import stock from '../views/stock.vue'
import sheet from '../views/sheet.vue'
import login from '../views/login.vue'
import unreceived from '../views/unreceived.vue'
import store from '../store'
import kefu from '../views/kefu.vue'
import { Indicator } from 'mint-ui'
import { Toast ,Notify,Dialog  } from 'vant'
Vue.use(VueRouter)
// 解决报错
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}
const qs = require('qs')
const routes = [
  {
    path: '/WxAuth',
    name: 'WxAuth',
    component: WxAuth,
    meta: {
      title: '智珠侠-微信授权'
    }
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: {
      title: '智珠侠'
    }
  },
  {
    path: '/whole',
    name: 'whole',
    component: Whole,
    meta: {
      title: '智珠侠-全部应用'
    }
  },
  {
    path: '/outPut',
    name: 'outPut',
    component: Output,
    meta: {
      title: '智珠侠-销项发票'
    }
  },
  {
    path: '/income',
    name: 'income',
    component: Income,
    meta: {
      title: '智珠侠-进项发票'
    }
  },
  {
    path: '/funding',
    name: 'funding',
    component: Funding,
    meta: {
      title: '智珠侠-资金情况'
    }
  },
  {
    path: '/statement',
    name: 'statement',
    component: Statement,
    meta: {
      title: '智珠侠-利润表'
    }
  },
  {
    path: '/profit',
    name: 'profit',
    component: profit,
    meta: {
      title: '智珠侠-利润情况'
    }
  },
  {
    path: '/tax',
    name: 'tax',
    component: tax,
    meta: {
      title: '智珠侠-纳税情况'
    }
  },
  {
    path: '/payable',
    name: 'payable',
    component: payable,
    meta: {
      title: '智珠侠-应付项目'
    }
  },
  {
    path: '/receivable',
    name: 'receivable',
    component: receivable,
    meta: {
      title: '智珠侠-应收项目'
    }
  },
  {
    path: '/stock',
    name: 'stock',
    component: stock,
    meta: {
      title: '智珠侠-存货'
    }
  },
  {
    path: '/sheet',
    name: 'sheet',
    component: sheet,
    meta: {
      title: '智珠侠-资产负债表'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: {
      title: '智珠侠-登录'
    }
  },
  {
    path: '/unreceived',
    name: 'unreceived',
    component: unreceived,
    meta: {
      title: '智珠侠-未达发票'
    }
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: '智珠侠'
    }
  },
  {
    path: '/kefu',
    name: 'kefu',
    component: kefu,
    meta: {
      title: '智珠侠-客服'
    }
  },
  {
    path: '/cs',
    name: 'cs',
    component: kefu,
    meta: {
      title: '智珠侠-客服'
    }
  },
  {
    path: '/tax-generation-reminder',
    name: 'tax-generation-reminder',
    component: () => import('@/views/publish-message/taxGenerationReminder.vue'),
    meta: {
      title: '智珠侠-税费生成提醒'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // next()
  // if (/micromessenger/i.test(navigator.userAgent)) {
  //   next()
  // }
  // 不要对 WxAuth 路由进行拦截，不进入 WxAuth 路由就拿不到微信返回的授权 code
  const wxUserInfo = store.state.wxUserInfo
  if (['cs','unreceived','WxAuth','login'].includes(to.name) || ['/cs','/unreceived','/WxAuth','/login'].includes(window.location.pathname)) {
    next()
    // Notify({ type: 'success', message: `${to.name},${window.location.pathname}` });
    return
  }
  // // const wxUserInfo = localStorage.getItem('wxUserInfo')
  // console.log(44444)
  // console.log(55555)
  // console.log(wxUserInfo)
  if (wxUserInfo === '') {
    // 保存当前路由地址，授权后还会跳到此地址
    // localStorage.setItem('wxRedirectUrl', to.fullPath)
    // 请求微信授权,并跳转到 /WxAuth 路由
    // const appId = 'wx27add8fe78aad9f1'
    // const redirectUrl = encodeURIComponent('https://wx.spiderair.com/WxAuth')
    // window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`
    // 微信未授权登录跳转到授权登录页面
    const url = window.location.href
    // 解决重复登录url添加重复的code与state问题
    const parseUrl = qs.parse(url.split('?')[1])
    // let loginUrl = ''
    if (parseUrl.code && parseUrl.state) {
      console.log(parseUrl.code)
      console.log(parseUrl.state)
      // delete parseUrl.code
      // delete parseUrl.state
      // let loginUrl = `${url.split('?')[0]}?${qs.stringify(parseUrl)}`

      const code = parseUrl.code
      const url = `https://wechat.spiderair.com/api/wechatPubAuth?code=${code}`
      // eslint-disable-next-line no-undef
      axios.get(url)
        .then(response => {
          Indicator.close()
          const redirectUrl = localStorage.getItem('wxRedirectUrl')
          if (response.data.code === 1) {
            // localStorage.setItem('wxUserInfo', response.data.data)
            // localStorage.setItem('openid', response.data.data.openid)
            store.commit('setWxUserInfo', response.data.data)
            store.commit('setOpenid', response.data.data.openid)
            console.log('aaaa ' + redirectUrl)
            if ((typeof (response.data.data.userId) !== 'undefined' && response.data.data.userId !== 0) || redirectUrl === '/kefu') {
              console.log(226)
              // Toast('成功获取用户userId')
              // localStorage.setItem('userId', response.data.data.userId)
              console.log('WxAuth存储userId:' + response.data.data.userId)
              store.commit('setUserId', response.data.data.userId)
              // const redirectUrl = localStorage.getItem('wxRedirectUrl')
              if (redirectUrl === null) {
                console.log(233)
                router.push('/')
              } else {
                console.log(236)
                localStorage.removeItem('wxRedirectUrl')
                // Toast('failure')
                if (redirectUrl === '/home') {
                  console.log(240)
                  next()
                } else {
                  console.log(243)
                  console.log(redirectUrl)
                  console.log(to.fullPath)
                  router.push(redirectUrl)
                }
              }
            } else {
              console.log(250)
              router.push('/login')
            }
          } else {
            Toast('微信认证失败，请重新进入页面')
          }
        })
        .catch(function (error) {
          Notify({ type: 'danger', message: `${error}` });
          Dialog({ message: `请求地址：${url}` });
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            Toast(error.response.data)
            // console.log(error.response.status)
            // console.log(error.response.headers)
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            Toast(error.request)
          } else {
            // Something happened in setting up the request that triggered an Error
            Toast('Error', error.message)
          }
          console.log(error.config)
        })
    } else {
      // 保存当前路由地址，授权后还会跳到此地址
      localStorage.setItem('wxRedirectUrl', to.fullPath)
      // 请求微信授权,并跳转到 /WxAuth 路由
      const appId = 'wx27add8fe78aad9f1'
      if (to.fullPath !== 'https://wx.spiderair.com/kefu') {
        const redirectUrl = encodeURIComponent('https://wx.spiderair.com/home')
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
      } else {
        const redirectUrl = encodeURIComponent(to.fullPath)
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
      }
      // const redirectUrl = encodeURIComponent('https://wx.spiderair.com/home')
      // const redirectUrl = encodeURIComponent(to.fullPath)
      // window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
    }
  } else {
    if (to.meta.title) {
      document.title = to.meta.title
    }
    const userId = store.state.userId
    // if (!localStorage.getItem('userId')) {
    console.log('9999' + to.meta.title)
    if (to.meta.title === '智珠侠-客服') {
      console.log(312)
      router.replace('/cs')
      next()
      console.log(315)
      return
    }
    if (userId === '' || userId === 0) {
      console.log(319)
      router.replace('/login')
      next()
      // return
    }
    console.log(324)
    next()
  }
})

export default router
