<template>
  <div>
    <!-- <div style="position:fixed;top:50%;right:0;">
      <van-button type="primary" size="default" @click="goKefu">客服</van-button>
    </div> -->

    <div>
      <div :class="details == false ? 'backShort' : 'backLarge'">
        <div style="display: flex;flex-direction: row;justify-content: space-between;">
          <div class="backShortTitle" @click="selectCompany()">
            <span class="textClass">{{cname}}</span>
            <img src="/img/home_page_drop_down_box_btn.png"
              style="width:13px;height:8px;margin-top:4px;margin-left:5px;">
          </div>
          <div class="backShortTitle2" @click="testLayerFooter()">
            <span class="textClass">{{selected_inv_time}}</span>
            <img src="/img/home_page_drop_down_box_btn.png"
              style="width:13px;height:8px;margin-top:4px;margin-left:5px;">
          </div>
        </div>
        <div v-if="details" class="detailsContent">
          <div class="trade">
            <div style="width:100%;display: flex;flex-direction: row;">
              <div class="income">
                <span class="ho-title">营业收入</span>
                <span class="ho-content">{{homeProfit.operating_income}}</span>
              </div>
              <div class="income">
                <span class="ho-title">营业成本</span>
                <span class="ho-content">{{homeProfit.operating_costs}}</span>
              </div>
            </div>
            <div style="width:100%;display: flex;flex-direction: row;">
              <div class="income">
                <span class="ho-title">净利润</span>
                <span class="ho-content">{{homeProfit.net_profit}}</span>
              </div>
              <div class="income" style="width:50%;border-bottom: rgba(0, 108, 245, 1) 1px solid;"></div>
            </div>
          </div>
          <div class="receivable">
            <div style="width:100%;display: flex;flex-direction: row;">
              <div class="income">
                <span class="ho-title">应收</span>
                <span class="ho-content">{{homeDetailedAccounts.accounts_receivable.qmye}}</span>
              </div>
              <div class="income">
                <span class="ho-title">应付</span>
                <span class="ho-content">{{homeDetailedAccounts.accounts_payable.qmye}}</span>
              </div>
            </div>
            <div style="width:100%;display: flex;flex-direction: row;">
              <div class="income">
                <span class="titleRec">存货(期初)</span>
                <span class="ho-content">{{homeDetailedAccounts.stock_items.qcye}}</span>
              </div>
              <div class="income">
                <span class="titleRec">存货(期末)</span>
                <span class="ho-content">{{homeDetailedAccounts.stock_items.qmye}}</span>
              </div>
            </div>
          </div>
          <div style="border-bottom: rgba(0, 108, 245, 1) 1px solid;">
            <span class="taxes">纳税情况</span>
          </div>
          <div class="receivable">
            <div style="width:100%;display: flex;flex-direction: row;">
              <div class="income">
                <span class="ho-title">增值税</span>
                <span class="ho-content">{{homeTax.value_added_tax}}</span>
              </div>
              <div class="income">
                <span class="ho-title">附加税</span>
                <span class="ho-content">{{homeTax.additional_tax}}</span>
              </div>
            </div>
            <div style="width:100%;display: flex;flex-direction: row;">
              <div class="income">
                <span class="ho-title">印花税</span>
                <span class="ho-content">{{homeTax.stamp_duty}}</span>
              </div>
              <div class="income">
                <span class="titleRec">企业所得税</span>
                <span class="ho-content">--</span>
              </div>
            </div>
          </div>
        </div>
        <div @click="unfold()" class="divLine">
          <div class="line"></div>
        </div>
      </div>
      <div>
        <div style="display: flex;flex-direction: row;justify-content: space-between;">
          <!-- 销项 -->
          <div id="output" class="total" style="margin-left:20px;" @click="jump($event)">
            <div style="margin-left: -8%;">
              <span class="ho-invoice">销项发票</span>
              <span class="label">(总税额)</span>
            </div>
            <div style="margin-top:6px;">
              <span class="digital">{{sales_total_tax}}</span>
              <span class="unit">元</span>
            </div>
          </div>
          <!-- 进项 -->
          <div id="income" class="total" style="margin-right:20px;" @click="jump($event)">
            <div style="margin-left: -8%;">
              <span class="ho-invoice">进项发票</span>
              <span class="label">(总税额)</span>
            </div>
            <div style="margin-top:6px;">
              <span class="digital">{{input_total_tax}}</span>
              <span class="unit">元</span>
            </div>
          </div>
        </div>
        <!-- 应用 -->
        <div style="margin-top: 20px;">
          <div style="width:100%;display: flex;flex-direction: row;">
            <div id="funding" class="btn" @click="jump($event)">
              <img class="btnImg" src="/img/home_page_fund_situation_icon_btn.png">
              <span class="btnText">资金情况</span>
            </div>
            <div id="payroll" class="btn" @click="jump($event)">
              <img class="btnImg" src="/img/home_page_payroll_icon_btn.png">
              <span class="btnText">工资单</span>
            </div>
            <div id="resources" class="btn" @click="jump($event)">
              <img class="btnImg" src="/img/home_page_human_resources_icon_btn.png">
              <span class="btnText">人力资源</span>
            </div>
            <div id="stock" class="btn" @click="jump($event)">
              <img class="btnImg" src="/img/home_page_stock_icon_btn.png">
              <span class="btnText">存货</span>
            </div>
          </div>
          <div style="width:100%;display: flex;flex-direction: row;">
            <div id="profit" class="btn" @click="jump($event)">
              <img class="btnImg" src="/img/home_page_profit_situation_icon_btn.png">
              <span class="btnText">利润情况</span>
            </div>
            <div id="tax" class="btn" @click="jump($event)">
              <img class="btnImg" src="/img/home_page_tax_situation_icon_btn.png">
              <span class="btnText">纳税情况</span>
            </div>
            <div id="payable" class="btn" @click="jump($event)">
              <img class="btnImg" src="/img/home_page_payables_icon_btn.png">
              <span class="btnText">应付项目</span>
            </div>
            <div id="receivable" class="btn" @click="jump($event)">
              <img class="btnImg" src="/img/home_page_receivables_icon_btn.png">
              <span class="btnText">应收项目</span>
            </div>
          </div>
          <div style="width:100%;display: flex;flex-direction: row;">
            <div id="statement" class="btn" @click="jump($event)">
              <img class="btnImg" src="/img/home_page_income_statement_icon_btn.png">
              <span class="btnText">利润表</span>
            </div>
            <div id="whole" class="btn" @click="zhankai()" v-if="showWhole==false">
              <img class="btnImg" src="/img/home_page_whole_icon_btn.png">
              <span class="btnText">全部</span>
            </div>
            <div id="sheet" class="btn" @click="jump($event)" v-if="showWhole==true">
              <img class="btnImg" src="/img/home_page_balance_sheet_icon_btn.png">
              <span class="btnText" style="margin-left:-2px;">资产负债表</span>
            </div>
            <div id="management" class="btn" @click="jump($event)" v-if="showWhole==true">
              <img class="btnImg" src="/img/home_page_asset_management_icon_btn.png">
              <span class="btnText">资产管理</span>
            </div>
            <div id="delivery" class="btn" @click="jump($event)" v-if="showWhole==true">
              <img class="btnImg" src="/img/home_page_document_express_delivery_icon_btn.png">
              <span class="btnText">单据速递</span>
            </div>
          </div>
          <div style="width:100%;display: flex;flex-direction: row;" v-if="showWhole==true">
            <div id="upload" class="btn" @click="jump($event)">
              <img class="btnImg" src="/img/home_page_invoice_upload_icon_btn.png">
              <span class="btnText">发票上传</span>
            </div>
            <div id="over" class="btn" @click="jump($event)">
              <img class="btnImg" src="/img/hopme_page_control_over_invoices_icon_btn.png">
              <span class="btnText">发票管理</span>
            </div>
            <div id="marketing" class="btn" @click="jump($event)">
              <img class="btnImg" src="/img/home_page_marketing_management_icon_btn.png">
              <span class="btnText">市场营销</span>
            </div>
            <div id="business" class="btn" @click="jump($event)">
              <img class="btnImg" src="/img/home_page_business_circles_icon_btn.png">
              <span class="btnText">工商注册</span>
            </div>
          </div>
          <div style="width:100%;display: flex;flex-direction: row;" v-if="showWhole==true">
            <div id="legal" class="btn" @click="jump($event)">
              <img class="btnImg" src="/img/home_page_legal_adviser_icon_btn.png">
              <span class="btnText">法律顾问</span>
            </div>
            <div id="whole" class="btn" @click="zhankai()">
              <img class="btnImg" src="/img/home_page_whole_icon_btn.png">
              <span class="btnText">收起</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios'
import { Toast, Button } from 'vant'
import Vue from 'vue'
Vue.use(Button)

export default {
  name: 'Home',
  data: () => ({
    // company_name_array: [],
    details: false,
    openid: ''
  }),
  mounted () {
    if (window.history && window.history.pushState) {
      // 向历史记录中插入了当前页
      history.pushState(null, null, document.URL)
      window.addEventListener('popstate', this.goBack, false)
    }
    window.WeixinJSBridge.call('hideOptionMenu')
  },
  destroyed () {
    window.removeEventListener('popstate', this.goBack, false)
  },
  created () {
    this.$store.dispatch('initialcompanys')
    // this.company_name_array = this.$store.state.company_name_array
  },
  // mounted () {
  //   axios.get('https://wechat.spiderair.com/api/homeInvoiceData', {
  //     params: {
  //       company_id: this.$store.state.company.id,
  //       zq: this.$store.state.company.dqqj
  //     }
  //   }).then(response => {
  //     console.log('首页发票数据接口访问成功。')
  //     this.$store.commit('setHomeInvoiceData', response.data.data.sales_total_tax, response.data.data.input_total_tax)
  //   })
  // },
  computed: {
    showWhole () {
      return this.$store.state.showWhole
    },
    homeDetailedAccounts () {
      return this.$store.state.homeDetailedAccounts
    },
    homeProfit () {
      return this.$store.state.homeProfit
    },
    homeTax () {
      return this.$store.state.homeTax
    },
    selected_inv_time () {
      return this.$store.state.selected_inv_time
    },
    company_name_array () {
      return this.$store.state.company_name_array
    },
    company_inv_times () {
      const tempInvtiems = this.$store.state.company.company_inv_times
      if (tempInvtiems[0] !== '取消') {
        tempInvtiems.unshift('取消')
      }
      return tempInvtiems
    },
    cname () {
      return this.$store.state.company.name
    },
    dqqj () {
      return this.$store.state.company.dqqj
    },
    sales_total_tax () {
      return this.$store.state.sales_total_tax
    },
    input_total_tax () {
      return this.$store.state.input_total_tax
    }
  },
  methods: {
    goBack () {
      console.log('点击了浏览器的返回按钮')
      sessionStorage.clear()
      // window.history.back()
      window.WeixinJSBridge.call('closeWindow')
    },
    unfold () {
      const that = this
      that.details = !that.details
      // const openid = localStorage.getItem('openid')
      // console.log(openid)
    },
    testLayerFooter () {
      const that = this
      this.$layer.footer({
        content: '选择账期',
        btn: this.company_inv_times
      }).then(function (res) {
        var text = (res === 0 ? '取消' : '选项') + res
        console.log(text)
        that.$store.commit('switchInvTime', res)
      })
    },
    selectCompany () {
      const that = this
      this.$layer.footer({
        content: '选择公司',
        btn: this.company_name_array
      }).then(function (res) {
        // var text = (res === 0 ? '取消' : '选项') + res
        console.log(res)
        if (res !== 0) {
          that.$store.commit('switchCompany', res - 1)
        }
      })
    },
    zhankai () {
      // this.$router.push({ name: 'whole'})
      this.$store.commit('changeShowWhole', !this.$store.state.showWhole)
    },
    jump (e) {
      switch (e.currentTarget.id) {
        case 'output':
          this.$router.push({ name: 'outPut' })
          console.log(this.showWhole)
          break
        case 'income':
          this.$router.push({ name: 'income' })
          break
        case 'funding':
          this.$router.push({ name: 'funding' })
          break
        case 'payroll':
          // this.$router.push({ name: 'payroll'})
          Toast('该功能暂未开放...')
          break
        case 'resources':
          Toast('该功能暂未开放...')
          // this.$router.push({ name: 'unreceived' })
          // console.log(this.showWhole)
          break
        case 'stock':
          this.$router.push({ name: 'stock' })
          break
        case 'profit':
          this.$router.push({ name: 'profit' })
          break
        case 'tax':
          Toast('该功能暂未开放...')
          // this.$router.push({ name: 'tax' })
          break
        case 'payable':
          this.$router.push({ name: 'payable' })
          break
        case 'receivable':
          this.$router.push({ name: 'receivable' })
          break
        case 'statement':
          this.$router.push({ name: 'statement' })
          break
        case 'sheet':
          this.$router.push({ path: '/sheet' })
          break
        case 'management':
          // this.$router.push({ path: '/management'})
          Toast('该功能暂未开放...')
          break
        case 'delivery':
          // this.$router.push({ path: '/delivery'})
          Toast('该功能暂未开放...')
          break
        case 'upload':
          // this.$router.push({ path: '/upload'})
          Toast('该功能暂未开放...')
          break
        case 'over':
          // this.$router.push({ path: '/over'})
          Toast('该功能暂未开放...')
          break
        case 'marketing':
          // this.$router.push({ path: '/marketing'})
          Toast('该功能暂未开放...')
          break
        case 'business':
          // this.$router.push({ path: '/business'})
          Toast('该功能暂未开放...')
          break
        case 'legal':
          // this.$router.push({ path: '/legal'})
          Toast('该功能暂未开放...')
          break
      }
    }
    // goKefu () {
    //   this.$router.push('/kefu')
    // }
  }
}
</script>

<style>
.divLine {
  width: 100%;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.el-message {
  width: 90% !important;
}

.messageCenten {
  top: 45% !important;
}

.project-dropdown {
  /* 设置高度才能显示出滚动条 !important */
  height: 200px;
  overflow: auto;
}
.project-dropdown::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #f5f5f5;
}
.project-dropdown::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
  border-radius: 10px;
  background-color: #f5f5f5;
}

body {
  margin: 0 auto;
  background: rgba(246, 249, 255, 1);
}

.backShort {
  width: 100%;
  height: 53px;
  background-image: url("/img/home_page_small_bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.backLarge {
  width: 100%;
  height: 277px;
  background-image: url("/img/home_page_large_bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.backShortTitle {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  margin-left: 10px;
}

.backShortTitle2 {
  width: 80px;
  display: flex;
  flex-direction: row;
  align-items: right;
  justify-content: right;
  margin-top: 16px;
  margin-right: 5px;
}

.detailsContent {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.trade {
  background: rgba(17, 101, 255, 0.4);
  height: 60px;
  display: flex;
  flex-direction: column;
}

.receivable {
  height: 60px;
}

.income {
  height: 30px;
  width: 50%;
  border-right: rgba(0, 108, 245, 1) 1px solid;
  text-align: justify;
  text-align-last: justify;
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  border-bottom: rgba(0, 108, 245, 1) 1px solid;
}

.textClass {
  font-size: 12px;
  font-weight: 400;
  color: #fefefe;
}

.taxes {
  font-size: 12px;
  font-weight: 400;
  color: rgba(254, 254, 254, 0.9);
  margin-left: -77%;
}

.line {
  width: 23px;
  height: 2px;
  background: #ffffff;
}

.income span {
  /* 设置盒子为行内块 */
  /* display: inline-block; */
  /* 设置盒子内元素水平居中 */
  text-align: center;
  line-height: 30px;
}

.ho-title {
  font-size: 12px;
  font-weight: 400;
  color: rgba(254, 254, 254, 0.5);
  width: 50px;
}

.titleRec {
  font-size: 12px;
  font-weight: 400;
  color: rgba(254, 254, 254, 0.5);
  width: 65px;
}

.ho-content {
  font-size: 12px;
  font-weight: 400;
  color: rgba(254, 254, 254, 1);
}

.total {
  width: 150px;
  height: 80px;
  background: #ffffff;
  box-shadow: 0px 0px 38px 0px rgba(49, 128, 255, 0.08);
  border-radius: 12px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
}

.ho-invoice {
  font-size: 12px;
  font-weight: 400;
  color: #081b3a;
}

.label {
  font-size: 10px;
  font-weight: 400;
  color: rgba(8, 27, 58, 0.5);
}

.digital {
  font-size: 24px;
  font-weight: 500;
  color: #21509b;
}

.unit {
  font-size: 13px;
  font-weight: 500;
  color: #21509b;
}

.btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  width: 25%;
}

.btnImg {
  width: 53px;
  height: 53px;
  margin-bottom: 5px;
  margin-left: 5px;
}

.btnText {
  font-size: 12px;
  color: rgba(8, 27, 58, 1);
  text-align: center;
}

.el-message-box__wrapper {
  position: fixed;
  top: 44%;
  bottom: 0;
  left: 0;
  right: 0;
}

.el-message-box {
  width: 80% !important;
}
</style>
