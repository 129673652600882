<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { Indicator } from 'mint-ui'
export default {
  name: 'app',
  data: () => ({

  }),
  mounted () {
    // 如果支持 popstate 一般移动端都支持了
    if (window.history && window.history.pushState) {
      // 往历史记录里面添加一条新的当前页面的url
      history.pushState(null, null, document.URL)
      // 给 popstate 绑定一个方法 监听页面刷新
      window.addEventListener('popstate', this.backChange, false) // false阻止默认事件
    }
  },
  destroyed () {
    window.removeEventListener('popstate', this.backChange, false) // false阻止默认事件
  },
  watch: {
    $route (to, from) {
      console.log(to.path)
      localStorage.setItem('path', to.path)
    }
  },
  methods: {
    backChange () {
      Indicator.close()
      sessionStorage.clear()
    }
  }
}
</script>

<style>
/* 栅格布局 */
/* 通用 */
* {
  box-sizing: border-box;
}
.row:after {
  content: "";
  clear: both;
  display: block;
}
[class*="col-"] {
  float: left;
  padding: 0px 15px;
}
/*默认移动端适配*/
[class*="col-"] {
  width: 90px;
}

/* 布局结束 */
body {
  margin: 0 auto;
  background: rgba(246, 249, 255, 1);
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
