import Vue from 'vue'
import {
  Button,
  Col,
  Row,
  NoticeBar,
  List,
  Cell,
  CellGroup,
  DatetimePicker,
  Field,
  Calendar,
  Loading,
  Checkbox,
  CheckboxGroup,
  Skeleton,
  Empty,
  PullRefresh
} from 'vant'

Vue.use(Button)
Vue.use(Col)
Vue.use(Row)
Vue.use(NoticeBar)
Vue.use(List)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(DatetimePicker)
Vue.use(Field)
Vue.use(Calendar)
Vue.use(Loading)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Skeleton)
Vue.use(Empty)
Vue.use(PullRefresh)
