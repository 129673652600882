<template>
  <div>
    <!-- 公司选择 -->
    <div class="backShort">
      <div style="display: flex;flex-direction: row;justify-content: space-between;">
        <div class="backShortTitle">
          <span class="textClass">{{cname}}</span>
        </div>
        <div class="backShortTitle2" @click="testLayerFooter()">
          <span class="textClass">{{selected_inv_time}}</span>
          <img src="/img/home_page_drop_down_box_btn.png" style="width:13px;height:8px;margin-top:4px;margin-left:5px;">
        </div>
      </div>
    </div>
    <!-- 版本选择 -->
    <!--    <div class="st-select">-->
    <!--      <div @click="version()" style="display:flex;flex-direction: row;align-items:center;">-->
    <!--        <span class="st-s-text" v-if="kjzd === 1">2006版</span>-->
    <!--        <span class="st-s-text" v-if="kjzd == 2">2001版</span>-->
    <!--        <img src="/img/qietu/pay_taxes_drop_down_btn.png"-->
    <!--          style="width:10px;height:10px;margin-top:4px;margin-left:5px;">-->
    <!--      </div>-->
    <!--    </div>-->
    <!-- 资产 -->
    <div>
      <div class="secondary1">
        <span class="data">一、资产</span>
      </div>
      <div>
        <div v-for="item of zc" :key="item.id">
          <div class="secondary2" v-if="item.is_title===1 && item.hc===null">
            <span class="sh-s-text">{{item.xmmc}}</span>
            <!-- <img style="width:13px;height:12px;margin-left:5px;" src="/img/qietu/profit_income_drop_down_btn.png"> -->
          </div>
          <div class="secondary" style="background: rgba(244, 245, 248, 1);" v-if="item.is_title!=1">
            <span class="name">{{item.xmmc}}</span>
            <div class="st-s-content">
              <span>期末余额：{{item.tjsj1}}</span>
              <span>年初余额：{{item.tjsj2}}</span>
            </div>
          </div>
          <div class="secondary1" style="height: 62px;" v-if="item.is_title===1 && item.hc!=null">
            <span class="name">{{item.xmmc}}</span>
            <div class="st-s-content">
              <span>期末余额：{{item.tjsj1}}</span>
              <span>年初余额：{{item.tjsj2}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 负债 -->
    <div style="margin-top: 15px;margin-bottom: 15px;">
      <div class="secondary1">
        <span class="data">二、负债和所有者(或股东)权益</span>
      </div>
      <div>
        <div v-for="item of fz" :key="item.id">
          <div class="secondary2" v-if="item.is_title===1 && item.hc===null">
            <span class="sh-s-text">{{item.xmmc}}</span>
            <!-- <img style="width:13px;height:12px;margin-left:5px;" src="/img/qietu/profit_income_drop_down_btn.png"> -->
          </div>
          <div class="secondary" style="background: rgba(244, 245, 248, 1);" v-if="item.is_title!=1">
            <span class="name">{{item.xmmc}}</span>
            <div class="st-s-content">
              <span>期末余额：{{item.tjsj1}}</span>
              <span>年初余额：{{item.tjsj2}}</span>
            </div>
          </div>
          <div class="secondary1" style="height: 62px;" v-if="item.is_title===1 && item.hc!=null">
            <span class="name">{{item.xmmc}}</span>
            <div class="st-s-content">
              <span>期末余额：{{item.tjsj1}}</span>
              <span>年初余额：{{item.tjsj2}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { Indicator } from 'mint-ui'
export default {
  name: 'sheet',
  data: () => ({
    chaged_selected_inv_time: '',
    kjzdIsChange: false,
    changedKjzd: '',
    isChange: false,
    zc: [],
    fz: []
  }),
  computed: {
    kjzd () {
      if (!this.kjzdIsChange) {
        if (this.$store.state.company.tax_attributes !== 0) {
          return this.$store.state.company.bbzd
        } else {
          return 0
        }
      } else {
        return this.changedKjzd
      }
    },
    selected_inv_time () {
      if (!this.isChange) {
        return this.$store.state.selected_inv_time
      } else {
        return this.chaged_selected_inv_time
      }
    },
    cid () {
      return this.$store.state.company.id
    },
    cname () {
      return this.$store.state.company.name
    },
    dqqj () {
      return this.$store.state.dqqj
    },
    company_inv_times () {
      const tempInvtiems = this.$store.state.company.company_inv_times
      if (tempInvtiems[0] !== '取消') {
        tempInvtiems.unshift('取消')
      }
      return tempInvtiems
    }
  },
  mounted () {
    window.WeixinJSBridge.call('hideOptionMenu')
  },
  created () {
    Indicator.open('加载中...')
    axios.get('https://wechat.spiderair.com/api/balance', {
      params: {
        company_id: this.cid,
        zq: this.selected_inv_time,
        lx: 0,
        kjzd: this.kjzd
      }
    }).then(response => {
      Indicator.close()
      const resData = response.data.data
      this.zc = resData.zc
      this.fz = resData.fz
    })
  },
  methods: {
    testLayerFooter () {
      const that = this
      this.$layer.footer({
        content: '选择账期',
        btn: this.company_inv_times
      }).then(function (res) {
        if (res !== 0) Indicator.open('加载中...')
        that.chaged_selected_inv_time = that.company_inv_times[res]
        that.isChange = false
        that.isChange = true
        axios.get('https://wechat.spiderair.com/api/balance', {
          params: {
            company_id: that.cid,
            zq: that.selected_inv_time,
            lx: 0,
            kjzd: that.kjzd
          }
        }).then(response => {
          Indicator.close()
          const resData = response.data.data
          that.zc = resData.zc
          that.fz = resData.fz
        })
      })
    },
    version () {
      const that = this
      this.$layer.footer({
        content: '选择版本',
        btn: ['取消', '2006版', '2001版']
      }).then(function (res) {
        if (res !== 0) Indicator.open('加载中...')
        that.kjzdIsChange = false
        that.kjzdIsChange = true
        if (res === 1) {
          that.changedKjzd = 1 // 2006版：1
        } else if (res === 2) {
          that.changedKjzd = 2 // 2001版：2
        }
        axios.get('https://wechat.spiderair.com/api/balance', {
          params: {
            company_id: that.cid,
            zq: that.selected_inv_time,
            lx: 0,
            kjzd: that.kjzd
          }
        }).then(response => {
          Indicator.close()
          const resData = response.data.data
          that.zc = resData.zc
          that.fz = resData.fz
        })
      })
    }
  }
}
</script>

<style>
.company {
  width: 100%;
  height: 37px;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 20px;
  border-top: 1px solid rgba(136, 136, 136, 0.22);
}
.name {
  font-size: 13px;
  font-weight: 400;
  color: #081b3a;
  line-height: 37px;
}
.data {
  font-size: 15px;
  font-weight: 800;
  /* color: #3180ff; */
  color: #024abd;
  line-height: 37px;
}
.img {
  width: 13px;
  height: 11px;
  margin-left: 5px;
}

.sh-select {
  width: 100%;
  height: 38px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0px 20px;
}
.sh-s-text {
  font-size: 13px;
  font-weight: 400;
  /* color: rgba(8, 27, 58, 0.8); */
  color: #3d87ff;
}

.secondary {
  width: 100%;
  height: 62px;
  background: #ffffff;
  border-bottom: 1px solid rgba(136, 136, 136, 0.22);
  text-align: left;
  padding: 0px 20px;
}
.secondary1 {
  width: 100%;
  height: 30px;
  background: #ffffff;
  text-align: left;
  padding: 0px 20px;
}
.secondary2 {
  width: 100%;
  height: 30px;
  background: #ffffff;
  border-bottom: 1px solid rgba(136, 136, 136, 0.22);
  text-align: left;
  padding: 0px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
}
.st-s-content {
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 400;
  color: rgba(8, 27, 58, 0.5);
}
</style>
