<template>
  <div>
    <div class="backShort">
      <div style="display: flex;flex-direction: row;justify-content: space-between;">
        <div class="backShortTitle">
          <span class="textClass">{{cname}}</span>
        </div>
        <div class="backShortTitle2" @click="testLayerFooter()">
          <span class="textClass">{{selected_inv_time}}</span>
          <img src="/img/home_page_drop_down_box_btn.png" style="width:13px;height:8px;margin-top:4px;margin-left:5px;">
        </div>
      </div>
    </div>
    <div class="pro-open">
      <!-- <span class="show">{{total_profit}}</span>
      <span class="prompt">(利润总额)</span> -->
      <span class="st-title" style="margin-top: 20px;">利润总额（元）</span>
      <span class="st-data">{{total_profit}}</span>
      <div class="sort">
        <div class="pro-directory">
          <span class="s-title">营业收入</span>
          <span class="s-content">{{operating_income}}</span>
        </div>
        <div class="s-line"></div>
        <div class="pro-directory">
          <span class="s-title">营业成本</span>
          <span class="s-content">{{operating_costs}}</span>
        </div>
      </div>
    </div>
    <div>
      <!-- <div class="con">
        <div style="display: flex;flex-direction: row;margin-left: 10px;">
          <div class="pro" id="pro" @click="cutover($event)">
            <span :class="select=='pro' ? 'pro-text' : 'iae-text'">利润趋势</span>
            <div v-if="select=='pro'" class="underscore"></div>
          </div>
          <div class="iae" id="iae" @click="cutover($event)">
            <span :class="select=='iae' ? 'pro-text' : 'iae-text'">收支情况</span>
            <div v-if="select=='iae'" class="underscore"></div>
          </div>
        </div>
        <span class="con-text">2019年07月</span>
      </div>
      <div class="chart">
        <div id="proChart" style="width: 94%;height: 170px;"></div>
        <span class="c-title">点击图表查看明细</span>
      </div> -->
      <div>
        <div class="detail">
          <div style="text-align: left;">
            <span class="d-title">收支明细</span>
            <div style="display: flex;flex-direction: row;align-items: center;" @click="changeA()">
              <span class="d-text">收入</span>
              <img src="/img/qietu/profit_income_drop_down_btn.png" style="width:10px;height:8px;margin-left:5px;">
            </div>
          </div>
          <div style="display: flex;flex-direction: column;margin-top:10px;text-align: right;">
            <!--            <span class="d-title">2019年07月</span>-->
            <span class="d-text">{{revenue}}</span>
          </div>
        </div>
        <div class="detailed" v-if="dakaiA">
          <div class="business">
            <span>主营业务收入+其他业务收入</span>
            <span>{{operating_income}}</span>
          </div>
          <div style="border-top:1px solid rgba(136, 136, 136, 0.22);"></div>
          <div class="business" style="text-align: right;">
            <span>营业外收入</span>
            <span>{{non_operating_income}}</span>
          </div>
        </div>
        <div class="outlay">
          <div style="display: flex;flex-direction: row;align-items: center;" @click="changeB()">
            <span class="d-text">支出</span>
            <img style="width:10px;height:8px;margin-left:5px;" src="/img/qietu/profit_income_drop_down_btn.png">
          </div>
          <span class="d-text">{{expenses}}</span>
        </div>
        <div class="detailed" v-if="dakaiB">
          <div class="business">
            <span>主营业务成本+其他业务成本</span>
            <span>{{operating_costs}}</span>
          </div>
          <div style="border-top:1px solid rgba(136, 136, 136, 0.22);"></div>
          <div class="business" style="text-align: right;">
            <span>营业外支出</span>
            <span>{{non_operating_expenses}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { Indicator } from 'mint-ui'
export default {
  name: 'profit',
  data: () => ({
    dakaiA: true,
    dakaiB: true,
    chaged_selected_inv_time: '',
    isChange: false,
    operating_income: '-',
    operating_costs: '-',
    non_operating_income: '-',
    non_operating_expenses: '-',
    total_profit: '-',
    expenses: '-',
    revenue: '-',
    select: 'pro',
    proChart: {},
    proObj: {
      color: ['#3398DB'],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          // 坐标轴指示器，坐标轴触发有效
          type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
        }
      },
      grid: {
        top: '15%',
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: [
        {
          name: '个人',
          type: 'category',
          data: [],
          // show: false,
          axisTick: {
            alignWithLabel: true
          }
        }
      ],
      yAxis: [
        {
          name: '吨数/MT',
          type: 'value'
        }
      ],
      series: [
        {
          type: 'bar',
          barWidth: '40%',
          data: []
        }
      ]
    }
  }),
  computed: {
    kjzd () {
      // return this.$store.state.company.tax_attributes
      if (this.$store.state.company.tax_attributes !== 0) {
        return this.$store.state.company.bbzd
      } else {
        return this.$store.state.company.tax_attributes
      }
    },
    selected_inv_time () {
      if (!this.isChange) {
        return this.$store.state.selected_inv_time
      } else {
        return this.chaged_selected_inv_time
      }
    },
    cid () {
      return this.$store.state.company.id
    },
    cname () {
      return this.$store.state.company.name
    },
    dqqj () {
      return this.$store.state.dqqj
    },
    company_inv_times () {
      const tempInvtiems = this.$store.state.company.company_inv_times
      if (tempInvtiems[0] !== '取消') {
        tempInvtiems.unshift('取消')
      }
      return tempInvtiems
    }
  },
  mounted () {
    window.WeixinJSBridge.call('hideOptionMenu')
  },
  created () {
    Indicator.open('加载中...')
    // 调用绘制图表的方法
    // this.draw()
    axios.get('https://wechat.spiderair.com/api/profitSituation', {
      params: {
        company_id: this.cid,
        zq: this.selected_inv_time,
        lx: 0,
        kjzd: this.kjzd
      }
    }).then(response => {
      Indicator.close()
      const resData = response.data.data
      this.operating_income = resData.operating_income
      this.operating_costs = resData.operating_costs
      this.non_operating_income = resData.non_operating_income
      this.non_operating_expenses = resData.non_operating_expenses
      this.total_profit = resData.total_profit
      this.expenses = resData.expenses
      this.revenue = resData.revenue
    })
  },
  methods: {
    changeA () {
      this.dakaiA = !this.dakaiA
    },
    changeB () {
      this.dakaiB = !this.dakaiB
    },
    cutover (e) {
      console.log(e.currentTarget.id)
      const that = this
      if (e.currentTarget.id === 'pro') {
        that.select = 'pro'
      } else if (e.currentTarget.id === 'iae') {
        that.select = 'iae'
      }
    },
    draw () {
      // 实例化echarts对象
      // this.proChart = Echarts.init(document.getElementById('proChart'))
      // 绘制条形图
      // const option = {
      //   title: {
      //     text: '地区点位、设备核对进度',
      //     top: 5,
      //     left: 'center'
      //   },
      //   legend: {
      //     data: ['衣服', '帽子'],
      //     top: 30
      //   },
      //   // X轴
      //   xAxis: {
      //     data: [
      //       '一月', '二月', '三月', '四月', '五月'
      //     ]
      //   },
      //   // Y轴
      //   yAxis: {},
      //   // 数据
      //   series: [
      //     {
      //       name: '衣服',
      //       type: 'bar',
      //       data: [120, 100, 440, 320, 150]
      //     },
      //     {
      //       name: '帽子',
      //       type: 'bar',
      //       data: [200, 120, 240, 330, 170]
      //     }
      //     // {
      //     //   name: 'bar',
      //     //   type: 'line',
      //     //   data: [120, 200, 240, 260, 300]
      //     // },
      //     // {
      //     //   name: 'bar',
      //     //   type: 'line',
      //     //   data: [120, 200, 300, 140, 260]
      //     // }
      //   ]
      // }
      // this.proChart.setOption(this.proObj)
    },
    testLayerFooter () {
      const that = this
      this.$layer.footer({
        content: '选择账期',
        btn: this.company_inv_times
      }).then(function (res) {
        if (res !== 0) Indicator.open('加载中...')
        that.chaged_selected_inv_time = that.company_inv_times[res]
        that.isChange = false
        that.isChange = true
        axios.get('https://wechat.spiderair.com/api/profitSituation', {
          params: {
            company_id: that.cid,
            zq: that.chaged_selected_inv_time,
            lx: 0,
            kjzd: that.kjzd
          }
        }).then(response => {
          Indicator.close()
          const resData = response.data.data
          that.operating_income = resData.operating_income
          that.operating_costs = resData.operating_costs
          that.non_operating_income = resData.non_operating_income
          that.non_operating_expenses = resData.non_operating_expenses
          that.total_profit = resData.total_profit
          that.expenses = resData.expenses
          that.revenue = resData.revenue
        })
      })
    }
  }
}
</script>

<style>
.company {
  width: 100%;
  height: 37px;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 20px;
  border-top: 1px solid rgba(136, 136, 136, 0.22);
}
.name {
  font-size: 13px;
  font-weight: 400;
  color: #081b3a;
  line-height: 37px;
}
.data {
  font-size: 13px;
  font-weight: 400;
  color: #3180ff;
  line-height: 37px;
}
.img {
  width: 13px;
  height: 11px;
  margin-left: 5px;
}

.pro-open {
  height: 145px;
  display: flex;
  flex-direction: column;
}
.show {
  font-size: 29px;
  font-weight: 500;
  color: #3180ff;
  margin-top: 20px;
}
.prompt {
  font-size: 13px;
  font-weight: 400;
  color: rgba(8, 27, 58, 0.5);
}

.sort {
  margin-top: 20px;
  height: 41px;
  display: flex;
  flex-direction: row;
  width: 100%;
}
.pro-directory {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.s-line {
  height: 100%;
  width: 1px;
  background: rgba(42, 126, 250, 0.5);
}
.s-title {
  font-size: 14px;
  font-weight: 400;
  color: rgba(8, 27, 58, 0.8);
}
.s-content {
  font-size: 12px;
  font-weight: 500;
  color: #2d2d2d;
}

.con {
  height: 31px;
  width: 100%;
  background: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.pro {
  width: 60px;
  margin: 0px 10px;
}
.iae {
  width: 60px;
  margin: 0px 10px;
}
.pro-text {
  font-size: 14px;
  font-weight: 400;
  color: #3180ff;
  margin-bottom: 5px;
}
.iae-text {
  font-size: 14px;
  font-weight: 400;
  color: rgba(8, 27, 58, 0.4);
}
.underscore {
  width: 100%;
  height: 1px;
  background: #3180ff;
}
.con-text {
  font-size: 14px;
  font-weight: 400;
  color: rgba(8, 27, 58, 0.4);
  line-height: 35px;
  margin-right: 10px;
}

.chart {
  width: 100%;
  height: 200px;
  background: #ffffff;
}
.c-title {
  font-size: 12px;
  font-weight: 400;
  color: rgba(8, 27, 58, 0.5);
}
.detail {
  width: 100%;
  height: 67px;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(136, 136, 136, 0.22);
  margin-top: 10px;
  padding: 0px 20px;
}
.d-title {
  font-size: 16px;
  font-weight: 400;
  color: #081b3a;
  line-height: 20px;
}
.d-text {
  font-size: 14px;
  font-weight: 400;
  color: rgba(8, 27, 58, 0.9);
  line-height: 30px;
}
.detailed {
  width: 100%;
  height: 93px;
  background: #f4f5f8;
  padding: 0px 20px;
  border-bottom: 1px solid rgba(136, 136, 136, 0.22);
}
.business {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: rgba(8, 27, 58, 0.5);
  line-height: 40px;
  text-align: left;
}

.outlay {
  width: 100%;
  height: 47px;
  background: #ffffff;
  border-bottom: 1px solid rgba(136, 136, 136, 0.22);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
}
</style>
