<template>
  <div style="display:flex;justify-content:center;align-items:center;">
    <!-- <div class="qrcode-wrapper-container">
      <h1>扫码添加专属客服</h1>
      <div class="qrcode" ref="qrCodeUrl"></div>
    </div> -->
    <!-- <img src="../assets/kefu_02.jpg" alt="" style="width: 100%" /> -->
    <!-- <van-notice-bar left-icon="volume-o" text="客服功能正在重建中"/>
    <van-cell-group>
      <van-cell title="用户名" :value="userInfo.name" />
      <van-cell title="手机号" :value="userInfo.phone" />
      <van-cell title="邮箱" :value="userInfo.email" />
      <van-cell title="所属公司" :value="userInfo.company" />
    </van-cell-group>

    <van-row>
      <van-col span="12">
        <van-button block icon="service-o" type="primary" @click="goKefu">访问客服</van-button>
      </van-col>
      <van-col span="12">
        <van-button block type="primary" @click="goBack">关闭页面</van-button>
      </van-col>
    </van-row> -->
  </div>
</template>

<script>
import axios from 'axios'
import QRCode from 'qrcodejs2'

// 判断 是否进入的是移动端
function isMobile () {
  const flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
  console.log(flag)
  return flag
}

export default {
  name: 'kefu',
  data: () => ({
    openid: '',
    userInfo: {},
    timer: null,
    isMobile: false
  }),
  created () {
    // this.openid = localStorage.getItem('openid')
    this.isMobile = isMobile()
    this.openid = this.$store.state.openid
    axios
      .get('https://wechat.spiderair.com/api/userinfo', {
        params: {
          openid: this.openid
        }
      })
      .then((response) => {
        console.log('客服链接', response.data.data.kefu_path)
        // if (isMobile()) {
        //   window.location.href = response.data.data.kefu_path
        // } else {
        //   this.creatQrCode(response.data.data.kefu_path)
        // }
        // window.location.href = response.data.data.kefu_path
        // window.location.href = 'https://developers.weixin.qq.com/community/homepage'
        // 测试企业微信客服部分
        this.userInfo = response.data.data
        this.goKefu()
        // Toast(`客服链接：${window.location}`)
        // console.log(6666)
        // eslint-disable-next-line no-unused-vars
        // var _53code = document.createElement('script')
        // _53code.src = 'https://tb.53kf.com/code/code/43224dd1844719d3779a8e147a541cff7/2'
        // var s = document.getElementsByTagName('script')[0]
        // s.parentNode.insertBefore(_53code, s)
        // var _53data = document.createElement('script')
        // var userData = '{id:' + response.data.data.id + ',name:' + '\'' + response.data.data.name + '\'' + ',email:' + '\'' + response.data.data.email + '\'' + ',phone:' + '\'' + response.data.data.phone + '\'' + ', notes:' + '\'' + response.data.data.company + '\'' + '}'

        // _53data.innerText = 'var _$53 = [];_$53.push([\'_setGuestInfo\', ' + userData + '])'
        // var sdata = document.getElementsByTagName('script')[0]
        // sdata.parentNode.insertBefore(_53data, sdata)

        // 下面的代码为注释代码
        // userData.name = response.data.data.name
        // userData.phone = response.data.data.phone
        // userData.notes = response.data.data.company
        // userData.email = response.data.data.email
        // userData.id = response.data.data.id
        // _53data.innerText = 'var _$53 = [];_$53.push([\'_setGuestInfo\', {id: 10513,name: \'陈超2\', email: \'222\', phone: \'222\', notes: \'测试公司2\'}])'
      })
  },
  mounted () {
    if (window.history && window.history.pushState) {
      console.log(window)
      // 向历史记录中插入了当前页
      history.pushState(null, null, document.URL)
      window.addEventListener('popstate', this.goBack, false)
    }
    window.WeixinJSBridge.call('hideOptionMenu')
  },
  beforeDestroy () {
    // window.location.href = this.kefuPath
  },
  destroyed () {
    window.removeEventListener('popstate', this.goBack, false)
  },
  methods: {
    goBack () {
      console.log('点击了浏览器的返回按钮')
      sessionStorage.clear()
      // window.history.back()
      window.WeixinJSBridge.call('closeWindow')
    },
    //
    goKefu () {
      window.location.href = this.userInfo.kefu_path
    },
    // 创建二维码
    creatQrCode (url) {
      const qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: url, // 需要转换为二维码的内容
        width: 100,
        height: 100,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
      console.log(qrcode)
    }

  },
  watch: {}
}
</script>

<style lang="less" scoped>
.qrcode-wrapper-container{
  margin: 0 auto;
  padding: 0 12px 12px;
  width: 280px;
  height: 280px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
  h1 {
    font-size: 16px;
  }
}
.qrcode {
  display: inline-block;
  background-color: pink;
  img {
    width: 132px;
    height: 132px;
    background-color: #fff;
    padding: 6px;
    box-sizing: border-box;
  }
}
</style>
