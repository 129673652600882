<template>
  <div>

  </div>
</template>

<script>
/**
 * 微信网页授权
 */
import { Toast } from 'vant'
import { Indicator } from 'mint-ui'
export default {
  name: 'WxAuth',
  created () {
    Indicator.open('加载中...')
    const that = this
    // 如果连接中有微信返回的 code，则用此 code 调用后端接口，向微信服务器请求用户信息
    // 如果不是从微信重定向过来的，没有带着微信的 code，则直接进入首页
    if (that.$route.query.code) {
      const code = that.$route.query.code
      const url = `https://wechat.spiderair.com/api/wechatPubAuth?code=${code}`
      // eslint-disable-next-line no-undef
      axios.get(url)
        .then(response => {
          Indicator.close()
          if (response.data.code === 1) {
            // localStorage.setItem('wxUserInfo', response.data.data)
            // localStorage.setItem('openid', response.data.data.openid)
            that.$store.commit('setWxUserInfo', response.data.data)
            that.$store.commit('setOpenid', response.data.data.openid)
            if (typeof (response.data.data.userId) !== 'undefined' && response.data.data.userId !== 0) {
              // Toast('成功获取用户userId')
              // localStorage.setItem('userId', response.data.data.userId)
              console.log('WxAuth存储userId:' + response.data.data.userId)
              that.$store.commit('setUserId', response.data.data.userId)
              const redirectUrl = localStorage.getItem('wxRedirectUrl')
              if (redirectUrl === null) {
                // Toast('success')
                that.$router.push('/')
              } else {
                localStorage.removeItem('wxRedirectUrl')
                // Toast('failure')
                that.$router.push(redirectUrl)
              }
            } else {
              that.$router.push('/login')
            }
          } else {
            Toast('微信认证失败，请重新进入页面')
          }
        })
        .catch(function (error) {
          
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            Toast(error.response.data)
            
            // console.log(error.response.status)
            // console.log(error.response.headers)
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            Toast(error.request)
            
          } else {
            // Something happened in setting up the request that triggered an Error
            Toast('Error', error.message)
            
          }
          console.log(error.config)
        })
    } else {
      that.$router.replace('/home')
    }
  }
}
</script>
