import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { Dialog } from 'vant'
Vue.use(Vuex)
Vue.use(Dialog)
export default new Vuex.Store({
  state: {
    showWhole: false,
    homeTax: {},
    homeProfit: {},
    homeDetailedAccounts: {},
    wxUserInfo: '',
    openid: '',
    userId: '',
    cid: 0,
    cname: '',
    companys: [],
    company: '',
    dqqj: '',
    company_name_array: ['取消'],
    sales_total_tax: '-',
    input_total_tax: '-',
    selected_inv_time: '-',
    selected_invoice_inv_times: ''
  },
  mutations: {
    changeShowWhole (state, show) {
      state.showWhole = show
    },
    setWxUserInfo (state, wxUserInfo) {
      state.wxUserInfo = wxUserInfo
    },
    setOpenid (state, openid) {
      state.openid = openid
    },
    setUserId (state, userId) {
      state.userId = userId
      console.log('store/index.js存储userId:' + userId)
    },
    getHomeInvoiceData (state, index) {
      if (typeof (state.company) !== 'object') {
        state.company = state.companys[index]
      }
      if (state.input_total_tax === '-' && state.sales_total_tax === '-') {
        axios.get('https://wechat.spiderair.com/api/homeInvoiceData', {
          params: {
            company_id: state.company.id,
            zq: state.company.dqqj
          }
        }).then(response => {
          console.log('首页发票数据接口访问成功。')
          state.sales_total_tax = response.data.data.sales_total_tax
          state.input_total_tax = response.data.data.input_total_tax
        })
      }
    },
    switchCompany (state, index) {
      state.company = state.companys[index]
      console.log('切换公司为：' + state.company.name)
      console.log('切换发票帐期为：' + state.company.invoice_inv_times[0])
      state.selected_inv_time = state.company.dqqj
      state.selected_invoice_inv_times = state.company.invoice_inv_times[0]
      axios.get('https://wechat.spiderair.com/api/homeInvoiceData', {
        params: {
          company_id: state.companys[index].id,
          zq: state.companys[index].dqqj
        }
      }).then(response => {
        state.sales_total_tax = response.data.data.sales_total_tax
        state.input_total_tax = response.data.data.input_total_tax
      })
      axios.get('https://wechat.spiderair.com/api/home', {
        params: {
          lx: 0,
          company_id: state.company.id,
          zq: state.company.company_inv_times[index]
        }
      }).then(response => {
        console.log(response.data.data)
        console.log(6666)
        state.homeDetailedAccounts = response.data.data.detailed_accounts
        state.homeProfit = response.data.data.profit
        state.homeTax = response.data.data.tax
      })
    },
    switchInvTime (state, index) {
      state.selected_inv_time = state.company.company_inv_times[index]
      axios.get('https://wechat.spiderair.com/api/homeInvoiceData', {
        params: {
          company_id: state.company.id,
          zq: state.company.company_inv_times[index]
        }
      }).then(response => {
        state.sales_total_tax = response.data.data.sales_total_tax
        state.input_total_tax = response.data.data.input_total_tax
      })
      axios.get('https://wechat.spiderair.com/api/home', {
        params: {
          lx: 0,
          company_id: state.company.id,
          zq: state.company.company_inv_times[index]
        }
      }).then(response => {
        console.log(response.data.data)
        console.log(6666)
        state.homeDetailedAccounts = response.data.data.detailed_accounts
        state.homeProfit = response.data.data.profit
        state.homeTax = response.data.data.tax
      })
    },
    switchinvoiceInvTime (state, index) {
      state.selected_invoice_inv_times = state.company.invoice_inv_times[index]
    },
    setCompanys (state, companys) {
      if (companys.length === 0) {
        Dialog.alert({
          message: '请联系管理员添加公司'
        }).then(() => {
          sessionStorage.clear()
          window.WeixinJSBridge.call('closeWindow')
        })
        return
      }
      if (typeof (state.company) !== 'object') {
        state.companys = companys
        state.company = companys[0]
        state.selected_inv_time = state.company.dqqj
        const cid = state.company.id
        const zq = state.selected_inv_time
        state.selected_invoice_inv_times = state.company.invoice_inv_times[0]
        for (const company in companys) {
          state.company_name_array.push(companys[company].name)
        }
        axios.get('https://wechat.spiderair.com/api/home', {
          params: {
            lx: 0,
            company_id: cid,
            zq: zq
          }
        }).then(response => {
          console.log(response.data.data)
          console.log(6666)
          state.homeDetailedAccounts = response.data.data.detailed_accounts
          state.homeProfit = response.data.data.profit
          state.homeTax = response.data.data.tax
        })
      }
    },
    setHomeInvoiceData (state, salesTotalTax, inputTotalTax) {
      state.sales_total_tax = salesTotalTax
      state.input_total_tax = inputTotalTax
    }
  },
  actions: {
    initialcompanys ({ commit, state }) {
      // axios.get('https://wechat.spiderair.com/api/mockGetCompanys')
      //   .then(response => {
      //     commit('setCompanys', response.data.data)
      //     commit('getHomeInvoiceData', 0)
      //   })
      const uid = state.userId
      // const uid = 10508
      console.log('请求uid:' + uid)
      axios.get('https://wechat.spiderair.com/api/mockGetCompanys', {
        params: {
          uid: uid
        }
      }).then(response => {
        console.log(response.data.data)
        console.log(111)
        commit('setCompanys', response.data.data)
        commit('getHomeInvoiceData', 0)
      })
    }
  },
  modules: {
  }
})
