<template>
  <div>
    <img src="/img/qietu/sign_logo_bg.png" class="logo">
    <input style="padding-left: 20px;" type="number" class="phone" placeholder="请输入已注册的手机号" v-model="mobile">
    <div class="verification">
      <input style="padding-left: 20px;" type="number" class="ver-input" placeholder="请输入手机验证码" v-model="code">
      <span class="ver-text" :style="verstyle" @click="sentSms()">{{vertext}}</span>
    </div>
    <button class="log-btn" @click="bindMobile()">登录</button>
  </div>
</template>

<script>
import axios from 'axios'
import { Toast } from 'vant'
import { Indicator } from 'mint-ui'
export default {
  name: 'login',
  data: () => ({
    clickable: true,
    vertext: '发送验证码',
    mobile: '',
    code: '',
    openid: '',
    verstyle: {
      'text-align': 'center',
      'font-size': '15px',
      'font-weight': '400',
      color: '#3180ff',
      position: 'relative',
      bottom: '34px',
      left: '120px'
    }
  }),
  mounted () {
    window.WeixinJSBridge.call('hideOptionMenu')
    if (window.history && window.history.pushState) {
      // 向历史记录中插入了当前页
      history.pushState(null, null, document.URL)
      window.addEventListener('popstate', this.goBack, false)
    }
  },
  created () {
    // this.openid = localStorage.getItem('openid')
    this.openid = this.$store.state.openid
  },
  destroyed () {
    window.removeEventListener('popstate', this.goBack, false)
  },
  methods: {
    goBack () {
      sessionStorage.clear()
      window.WeixinJSBridge.call('closeWindow')
    },
    sentSms () {
      if (!this.clickable) {
        return false
      }
      if (this.mobile.trim() === '') {
        Toast('请输入已注册的手机号')
        return false
      }
      const reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/
      const flag = reg.test(this.mobile)
      if (!flag) {
        Toast('手机号码不正确，请输入有效的手机号码')
        return false
      }
      Indicator.open('发送中...')
      axios.post('https://wechat.spiderair.com/api/requestRegisterSmsCode', {
        mobile: this.mobile
      })
        .then(response => {
          Indicator.close()
          this.clickable = false
          this.verstyle.color = 'gray'
          let num = 60
          this.vertext = num + '秒后重新获取'
          const timer = setInterval(() => {
            num--
            this.vertext = num + '秒后重新获取'
            if (num === 0) {
              this.verstyle.color = 'red'
              this.vertext = '获取验证码'
              clearInterval(timer)
              this.clickable = true
              this.verstyle.color = '#3180ff'
            }
          }, 1000)
          Toast(response.data.message)
        })
        .catch(function (error) {
          Indicator.close()
          if (typeof (error.response.data.errors) !== 'undefined') {
            const errs = error.response.data.errors
            for (const err in errs) {
              Toast(errs[err][0])
            }
          } else if (typeof (error.response.data.message) !== 'undefined') {
            Toast(error.response.data.message)
          }
        })
    },
    bindMobile () {
      const that = this
      if (this.mobile.trim() === '') {
        Toast('请输入已注册的手机号')
        return false
      }
      const reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/
      const flag = reg.test(this.mobile)
      if (!flag) {
        Toast('手机号码不正确，请输入有效的手机号码')
        return false
      }
      Indicator.open('发送中...')
      axios.post('https://wechat.spiderair.com/api/bindMobileWithOpenid', {
        mobile: this.mobile,
        code: this.code,
        openid: this.openid
      })
        .then(response => {
          Indicator.close()
          Toast(response.data.message)
          // localStorage.setItem('userId', response.data.data.userId)
          that.$store.commit('setUserId', response.data.data.userId)
          this.$router.replace('/')
        })
        .catch(function (error) {
          Indicator.close()
          if (typeof (error.response.data.errors) !== 'undefined') {
            const errs = error.response.data.errors
            for (const err in errs) {
              Toast(errs[err][0])
            }
          } else if (typeof (error.response.data.message) !== 'undefined') {
            Toast(error.response.data.message)
          }
        })
    }
  }
}
</script>

<style>
.logo {
  width: 81px;
  height: 45px;
  margin-top: 21px;
  justify-content: center;
}

.phone {
  width: 90%;
  height: 47px;
  background: #ffffff;
  border: 1px solid #e0ecf1;
  border-radius: 7px;
  margin-top: 29px;
  justify-content: center;
}

.verification {
  width: 90%;
  /* height: 47px; */
  margin-top: 13px;
  justify-content: center;
}
.ver-input {
  width: 100%;
  height: 47px;
  background: #ffffff;
  border: 1px solid #e0ecf1;
  border-radius: 7px;
  margin-left: 5.5%;
}
/*.ver-text {*/
/*  font-size: 15px;*/
/*  font-weight: 400;*/
/*  color: #3180ff;*/
/*  position: relative;*/
/*  top: -40px;*/
/*  right: -130px;*/
/*}*/

.log-btn {
  width: 167px;
  height: 33px;
  background: #3180ff;
  border-radius: 7px;
  border: 1px solid #fff;
  color: #fff;
  letter-spacing: 3px;
}
</style>
