<template>
  <div>
    <div class="backShort">
      <div style="display: flex;flex-direction: row;justify-content: space-between;">
        <div class="backShortTitle">
          <span class="textClass">{{cname}}</span>
        </div>
        <div class="backShortTitle2" @click="testLayerFooter()">
          <span class="textClass">{{invTimeStr}}</span>
          <img src="/img/home_page_drop_down_box_btn.png" style="width:13px;height:8px;margin-top:4px;margin-left:5px;">
        </div>
      </div>
    </div>
    <div class="st-select">
<!--      <div style="display:flex;flex-direction: row;align-items:center;">-->
<!--        <span class="st-s-text" v-if="kjzd === 1">2006版</span>-->
<!--        <span class="st-s-text" v-if="kjzd === 2">2001版</span>-->
<!--        <img src="/img/qietu/pay_taxes_drop_down_btn.png"-->
<!--          style="width:10px;height:10px;margin-top:4px;margin-left:5px;">-->
<!--      </div>-->
      <div @click="report()" style="display:flex;flex-direction: row;align-items:center;margin-left:5px;">
        <span class="st-s-text">{{reportFormat}}</span>
        <img src="/img/qietu/pay_taxes_drop_down_btn.png"
          style="width:10px;height:10px;margin-top:4px;margin-left:5px;">
      </div>
    </div>
<!--    <div class="secondary">-->
<!--      <span class="data">一、营业收入</span>-->
<!--      <div class="st-s-content">-->
<!--        <span>本年累计：{{item.tjsj1}}</span>-->
<!--        <span>本期金额：{{item.tjsj2}}</span>-->
<!--      </div>-->
<!--    </div>-->
    <div class="secondary" v-for="item of items" :key="item.id">
      <span class="name" v-bind:class="{data: item.is_title}">{{item.xmmc}}</span>
      <div class="st-s-content">
        <span>本年累计：{{item.tjsj1}}</span>
        <span>本期金额：{{item.tjsj2}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { Indicator } from 'mint-ui'
export default {
  name: 'statement',
  data: () => ({
    lx: 0,
    reportFormat: '月报',
    isSwitchToSeason: false,
    changedSelectedSeason: '',
    chaged_selected_inv_time: '',
    seasonIsChange: false,
    kjzdIsChange: false,
    changedKjzd: '',
    isChange: false,
    items: []
  }),
  created () {
    Indicator.open('加载中...')
    axios.get('https://wechat.spiderair.com/api/profit', {
      params: {
        company_id: this.cid,
        zq: this.selected_inv_time,
        lx: 0,
        kjzd: this.kjzd
      }
    }).then(response => {
      Indicator.close()
      const resData = response.data.data
      this.items = resData
    })
  },
  mounted () {
    window.WeixinJSBridge.call('hideOptionMenu')
  },
  computed: {
    invTimeArr () {
      if (!this.isSwitchToSeason) {
        return this.company_inv_times
      } else {
        return this.season
      }
    },
    invTimeStr () {
      if (!this.isSwitchToSeason) {
        return this.selected_inv_time
      } else {
        return this.selected_season
      }
    },
    season () {
      return this.$store.state.company.season
    },
    selected_season () {
      if (!this.seasonIsChange) {
        return this.$store.state.company.season[0]
      } else {
        return this.changedSelectedSeason
      }
    },
    kjzd () {
      if (!this.kjzdIsChange) {
        // return this.$store.state.company.tax_attributes
        if (this.$store.state.company.tax_attributes !== 0) {
          return this.$store.state.company.bbzd
        } else {
          return this.$store.state.company.tax_attributes
        }
      } else {
        return this.changedKjzd
      }
    },
    selected_inv_time () {
      if (!this.isChange) {
        return this.$store.state.selected_inv_time
      } else {
        return this.chaged_selected_inv_time
      }
    },
    cid () {
      return this.$store.state.company.id
    },
    cname () {
      return this.$store.state.company.name
    },
    dqqj () {
      return this.$store.state.dqqj
    },
    company_inv_times () {
      const tempInvtiems = this.$store.state.company.company_inv_times
      if (tempInvtiems[0] !== '取消') {
        tempInvtiems.unshift('取消')
      }
      return tempInvtiems
    }
  },
  methods: {
    testLayerFooter () {
      const that = this
      this.$layer.footer({
        content: '选择账期',
        btn: this.invTimeArr
      }).then(function (res) {
        var text = (res === 0 ? '取消' : '选项') + res
        if (res !== 0) Indicator.open('加载中...')
        console.log(text)
        if (!that.isSwitchToSeason) {
          that.chaged_selected_inv_time = that.company_inv_times[res]
          that.isChange = false
          that.isChange = true
          axios.get('https://wechat.spiderair.com/api/profit', {
            params: {
              company_id: that.cid,
              zq: that.chaged_selected_inv_time,
              lx: 0,
              kjzd: that.kjzd
            }
          }).then(response => {
            Indicator.close()
            const resData = response.data.data
            that.items = resData
          })
        } else {
          that.changedSelectedSeason = that.season[res]
          that.seasonIsChange = false
          that.seasonIsChange = true
          const invTime = that.changedSelectedSeason
          const year = invTime.substring(0, 4)
          const season = invTime.substring(6, 7)
          let requestInvTime = ''
          if (season === '1') {
            requestInvTime = year + '-01,' + year + '-02,' + year + '-03,'
          } else if (season === '2') {
            requestInvTime = year + '-04,' + year + '-05,' + year + '-06,'
          } else if (season === '3') {
            requestInvTime = year + '-07,' + year + '-08,' + year + '-09,'
          } else if (season === '4') {
            requestInvTime = year + '-10,' + year + '-11,' + year + '-12,'
          }
          axios.get('https://wechat.spiderair.com/api/profit', {
            params: {
              company_id: that.cid,
              zq: requestInvTime,
              lx: that.lx,
              kjzd: that.kjzd
            }
          }).then(response => {
            Indicator.close()
            const resData = response.data.data
            that.items = resData
          })
        }
      })
    },
    version () {
      const that = this
      this.$layer.footer({
        content: '选择版本',
        btn: ['取消', '2006版', '2001版']
      }).then(function (res) {
        if (res !== 0) Indicator.open('加载中...')
        that.kjzdIsChange = false
        that.kjzdIsChange = true
        if (res === 1) {
          that.changedKjzd = 1 // 2006版：1
          axios.get('https://wechat.spiderair.com/api/profit', {
            params: {
              company_id: that.cid,
              zq: that.selected_inv_time,
              lx: that.lx,
              kjzd: that.kjzd
            }
          }).then(response => {
            Indicator.close()
            const resData = response.data.data
            that.items = resData
          })
        } else if (res === 2) {
          that.changedKjzd = 2 // 2001版：2
          const invTime = that.invTimeStr
          const year = invTime.substring(0, 4)
          const season = invTime.substring(6, 7)
          let requestInvTime = ''
          if (season === '1') {
            requestInvTime = year + '-01,' + year + '-02,' + year + '-03,'
          } else if (season === '2') {
            requestInvTime = year + '-04,' + year + '-05,' + year + '-06,'
          } else if (season === '3') {
            requestInvTime = year + '-07,' + year + '-08,' + year + '-09,'
          } else if (season === '4') {
            requestInvTime = year + '-10,' + year + '-11,' + year + '-12,'
          }
          axios.get('https://wechat.spiderair.com/api/profit', {
            params: {
              company_id: that.cid,
              zq: requestInvTime,
              lx: that.lx,
              kjzd: that.kjzd
            }
          }).then(response => {
            Indicator.close()
            const resData = response.data.data
            that.items = resData
          })
        }
      })
    },
    report () {
      const that = this
      this.$layer.footer({
        content: '选择报表',
        btn: ['取消', '月报', '季报']
      }).then(function (res) {
        if (res !== 0) Indicator.open('加载中...')
        if (res === 1) {
          that.lx = 0
          that.reportFormat = '月报'
          that.isSwitchToSeason = true
          that.isSwitchToSeason = false
          axios.get('https://wechat.spiderair.com/api/profit', {
            params: {
              company_id: that.cid,
              zq: that.selected_inv_time,
              lx: that.lx,
              kjzd: that.kjzd
            }
          }).then(response => {
            Indicator.close()
            const resData = response.data.data
            that.items = resData
          })
        } else if (res === 2) {
          that.lx = 1
          that.reportFormat = '季报'
          that.isSwitchToSeason = false
          that.isSwitchToSeason = true
          const invTime = that.invTimeStr
          const year = invTime.substring(0, 4)
          const season = invTime.substring(6, 7)
          let requestInvTime = ''
          if (season === '1') {
            requestInvTime = year + '-01,' + year + '-02,' + year + '-03,'
          } else if (season === '2') {
            requestInvTime = year + '-04,' + year + '-05,' + year + '-06,'
          } else if (season === '3') {
            requestInvTime = year + '-07,' + year + '-08,' + year + '-09,'
          } else if (season === '4') {
            requestInvTime = year + '-10,' + year + '-11,' + year + '-12,'
          }
          axios.get('https://wechat.spiderair.com/api/profit', {
            params: {
              company_id: that.cid,
              zq: requestInvTime,
              lx: that.lx,
              kjzd: that.kjzd
            }
          }).then(response => {
            Indicator.close()
            const resData = response.data.data
            that.items = resData
          })
        }
      })
    }
  }
}
</script>

<style>
.company {
  width: 100%;
  height: 37px;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 20px;
  border-top: 1px solid rgba(136, 136, 136, 0.22);
}
.name {
  font-size: 13px;
  font-weight: 400;
  color: #081b3a;
  line-height: 37px;
}
.data {
  font-size: 13px;
  font-weight: 400;
  color: #3180ff;
  line-height: 37px;
}
.img {
  width: 13px;
  height: 11px;
  margin-left: 5px;
}

.st-select {
  width: 100%;
  height: 38px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0px 20px;
}
.st-s-text {
  font-size: 13px;
  font-weight: 400;
  color: rgba(8, 27, 58, 0.8);
}

.secondary {
  width: 100%;
  height: 62px;
  background: #ffffff;
  border-bottom: 1px solid rgba(136, 136, 136, 0.22);
  text-align: left;
  padding: 0px 20px;
}
.st-s-content {
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 400;
  color: rgba(8, 27, 58, 0.5);
}
</style>
