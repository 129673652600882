<template>
  <div>
    <div class="backShort">
      <div style="display: flex;flex-direction: row;justify-content: space-between;">
        <div class="backShortTitle">
          <span class="textClass">{{cname}}</span>
        </div>
        <div class="backShortTitle2" @click="testLayerFooter()">
          <span class="textClass">{{selected_inv_time}}</span>
          <img src="/img/home_page_drop_down_box_btn.png" style="width:13px;height:8px;margin-top:4px;margin-left:5px;">
        </div>
      </div>
    </div>
    <div class="st-content">
      <span class="st-title">库存资产（元）</span>
      <span class="st-data">{{qmye}}</span>
      <div class="st-sort">
        <div class="st-directory">
          <span class="st-s-title">{{qcye}}</span>
          <span class="st-s-content">期初合计（元）</span>
        </div>
        <div class="st-s-line"></div>
        <div class="st-directory" style="text-align: right;">
          <span class="st-s-title">{{qmye}}</span>
          <span class="st-s-content">期末合计（元）</span>
        </div>
      </div>
    </div>
    <div class="st-display">
      <div class="d-heading" @click="change()">
        <span class="d-title">{{subjectName}}</span>
        <img class="d-img" src="/img/qietu/income_statement_btn.png" v-if="hasChild">
      </div>
      <div class="d-css">
        <div class="period">
          <span class="p-text">{{qcye}}</span>
          <span class="p-content">期初</span>
        </div>
        <div class="end">
          <span class="p-text">{{qmye}}</span>
          <span class="p-content">期末</span>
        </div>
      </div>
    </div>
    <div v-for="item of items" :key="item.kmbh">
      <div class="st-display-i" v-if="dakai">
        <div class="d-heading">
          <span class="d-title">{{item.kmmc}}</span>
        </div>
        <div class="d-css">
          <div class="period">
            <span class="p-text">{{item.qcye}}</span>
            <span class="p-content">期初</span>
          </div>
          <div class="end">
            <span class="p-text">{{item.qmye}}</span>
            <span class="p-content">期末</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { Indicator } from 'mint-ui'
export default {
  name: 'stock',
  data: () => ({
    subjectName: '库存商品',
    subjectNo: 1405,
    isChange: false,
    chaged_selected_inv_time: '',
    dakai: false,
    shouru: '-',
    zhichu: '-',
    qcye: '-',
    qmye: '-',
    dqjffs: '-',
    dqdffs: '-',
    bnlj: '-',
    items: [],
    hasChild: false
  }),
  mounted () {
    window.WeixinJSBridge.call('hideOptionMenu')
  },
  created () {
    this.dakai = false
    Indicator.open('加载中...')
    axios.get('https://wechat.spiderair.com/api/detailedAccounts', {
      params: {
        company_id: this.cid,
        start: this.selected_inv_time,
        selected_sub: this.subjectNo,
        selected_subname: this.subjectName
      }
    }).then(response => {
      Indicator.close()
      const resData = response.data.data
      this.qcye = resData.qcye
      this.qmye = resData.qmye
      if (resData.item_list != null) {
        this.items = resData.item_list.children
        typeof (this.items) !== 'undefined' && this.items.length !== 0 ? this.hasChild = true : this.hasChild = false
      } else {
        this.hasChild = false
      }
    })
  },
  computed: {
    selected_inv_time () {
      if (!this.isChange) {
        return this.$store.state.selected_inv_time
      } else {
        return this.chaged_selected_inv_time
      }
    },
    cid () {
      return this.$store.state.company.id
    },
    cname () {
      return this.$store.state.company.name
    },
    dqqj () {
      return this.$store.state.dqqj
    },
    company_inv_times () {
      const tempInvtiems = this.$store.state.company.company_inv_times
      if (tempInvtiems[0] !== '取消') {
        tempInvtiems.unshift('取消')
      }
      return tempInvtiems
    }
  },
  methods: {
    change () {
      const that = this
      console.log(that.dakai)
      that.dakai = !that.dakai
      console.log(that.dakai)
    },
    testLayerFooter () {
      const that = this
      this.$layer.footer({
        content: '选择账期',
        btn: this.company_inv_times
      }).then(function (res) {
        if (res !== 0) Indicator.open('加载中...')
        that.chaged_selected_inv_time = that.company_inv_times[res]
        that.isChange = true
        axios.get('https://wechat.spiderair.com/api/detailedAccounts', {
          params: {
            company_id: that.cid,
            start: that.chaged_selected_inv_time,
            selected_sub: that.subjectNo,
            selected_subname: that.subjectName
          }
        }).then(response => {
          Indicator.close()
          const resData = response.data.data
          that.qcye = resData.qcye
          that.qmye = resData.qmye
          if (resData.item_list != null) {
            that.items = resData.item_list.children
            typeof (that.items) !== 'undefined' && that.items.length !== 0 ? that.hasChild = true : that.hasChild = false
            console.log(typeof (that.items))
            console.log(that.items.length)
          } else {
            that.hasChild = false
          }
        })
      })
    }
  }
}
</script>

<style>
.company {
  width: 100%;
  height: 37px;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 20px;
  border-top: 1px solid rgba(136, 136, 136, 0.22);
}
.name {
  font-size: 13px;
  font-weight: 400;
  color: #081b3a;
  line-height: 37px;
}
.data {
  font-size: 13px;
  font-weight: 400;
  color: #3180ff;
  line-height: 37px;
}
.img {
  width: 13px;
  height: 11px;
  margin-left: 5px;
}

.st-content {
  width: 100%;
  height: 160px;
  background: #ffffff;
  margin: 10px 0px;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
}
.st-title {
  font-size: 16px;
  font-weight: 400;
  color: #081b3a;
  text-align: center;
}
.st-data {
  font-size: 33px;
  font-weight: 500;
  color: #3180ff;
  margin: 12px 0px;
}
.st-sort {
  margin-bottom: 10px;
  width: 100%;
  height: 67px;
  padding: 10px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #ffffff;
}
.st-directory {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}
.st-s-line {
  height: 100%;
  width: 1px;
  background: rgba(42, 126, 250, 0.5);
}
.st-s-title {
  font-size: 16px;
  font-weight: 400;
  color: rgba(49, 128, 255, 1);
}
.st-s-content {
  font-size: 14px;
  font-weight: 500;
  color: rgba(8, 27, 58, 0.8);
}

.st-display-i {
  width: 100%;
  height: 73px;
  background: #f4f5f8;
  border-top: 1px solid rgba(136, 136, 136, 0.22);
  padding: 5px 20px;
}
.st-display {
  width: 100%;
  height: 73px;
  background: #ffffff;
  padding: 5px 20px;
}
.d-heading {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 3px;
}
.d-title {
  font-size: 16px;
  font-weight: 400;
  color: #081b3a;
}
.d-img {
  width: 14px;
  height: 12px;
  margin-left: 5px;
}
.d-css {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.period {
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
}
.end {
  display: flex;
  flex-direction: column;
  justify-content: right;
  text-align: right;
}
.p-text {
  font-size: 14px;
  font-weight: 400;
  color: rgba(8, 27, 58, 0.8);
}
.p-content {
  font-size: 12px;
  font-weight: 400;
  color: rgba(8, 27, 58, 0.5);
}
</style>
